import { useState, useEffect } from "react";

function useCountDown(initialTime: number) {
  const [timeLeft, setTimeLeft] = useState<number>(initialTime);
  const [timerId, setTimerId] = useState<NodeJS.Timeout | null>(null);

  function startTime() {
    if (timeLeft <= 0) {
      resetTime();
    }

    if (timerId) {
      clearInterval(timerId);
    }

    const id = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(id);
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    setTimerId(id);
  }

  function resetTime() {
    setTimeLeft(initialTime);
  }

  function formatTime(seconds: number) {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins}:${secs < 10 ? "0" : ""}${secs}`;
  }

  useEffect(() => {
    return () => {
      if (timerId) {
        clearInterval(timerId);
      }
    };
  }, [timerId]);

  const timeLeftData = formatTime(timeLeft);

  return { timeLeftData, startTime, resetTime };
}

export default useCountDown;
