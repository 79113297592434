import axios from "axios";
import { userManagerMain } from "index";
import { toast } from "react-toastify";
import { store } from "redux/store/Store";

axios.defaults.data = {};
axios.defaults.headers.common.Accept = "application/json";
axios.defaults.headers.common["Content-Type"] = "application/json";

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
// const CancelToken = axios.CancelToken;
// const source = CancelToken.source();

/////////// Request API ///////////
// export const cancelTokenSourceMain = axios.CancelToken.source();
export const requestInstance = axios.create();

//   {
//  cancelToken:source.token
//   }

requestInstance.interceptors.request.use(
  (config: any) => {
    const { token } = store.getState().authenticate;
    // config.headers.Authorization = `Bearer ${token}`;
    if (!config.skipAuth) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    const errorStatus = error?.response?.status;

    if (errorStatus >= 500) {
      toast.error(error.response.data?.errors?.message);
      return Promise.reject(error);
    }

    return Promise.reject(error);
  }
);

requestInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error?.response?.status === 401) {
      localStorage.clear();
      userManagerMain.signoutSilent();
    }

    if (error?.response?.status === 422) {
      handleError(error);
    }
    const page = window.location.pathname;
    // if(error?.response?.status === 406) return
    if (
      ![
        "/trips",
        "personel",
        "/trip-management",
        "/consignment-manage",
        "/trip-management-map",
        "/trip-map",
        "/consignment-manage-operation",
        "/barcode",
        "/Collect-management-map",
        "/delivery-management-map",
        "/delivery-management",
        "/order-management",
      ].includes(page)
    ) {
      if (error?.response?.status >= 500) {
        handleError(error);
        return Promise.reject(error);
      }
      // if (error?.response?.status < 500) {
      //   // handleError(error);
      // }
    }

    if (
      [
        "/consignment-manage",
        "personel",
        "/consignment-manage-operation",
        "/basic-information/map-zone",
        "/basic-information/map-zone-operation",
      ].includes(page)
    ) {
      throw error;
    } else {
      handleError(error);
    }

    return Promise.reject(error);
  }
);

export const mainService = requestInstance;
export default requestInstance;

export function handleError(error) {
  if (Array.isArray(error?.response?.data?.messages)) {
    for (let err of error?.response?.data?.messages) {
  
      toast.error(err?.message);
      return Promise.reject(error);
    }
  } else {
    // toast.error(error?.response?.data?.errors?.message);
    return Promise.reject(error);
  }
}
