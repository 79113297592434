import {
  ComplexPasswordRegex,
  JustEngNameRegex,
  JustEngPasswordRegex,
  mobileReg,
  MobileRegex,
  NationalCodeRegex,
  PasswordRegex,
  PostalCodeRegex,
  SpecialCharacter,
} from "tools/validations/ErrorHelper";
import {
  NOTTYPE,
  REQUIRED,
  UNMATCHPASSWORD,
  VALIDATEREQNUMBER,
  VALIDCOMPLEXREGEX,
  VALIDENGPASSWORD,
  VALIDLATINUSERNAME,
  VALIDMOBILE,
  VALIDNATIONALCODE,
  VALIDPOSTALCODE,
} from "tools/validations/RegexKeywords";
import * as Yup from "yup";

export const validationSchemaHub = () =>
  Yup.object({
    name: Yup.string().required(),
    selectHubCategory: Yup.object().shape({
      text: Yup.string().required(),
      id: Yup.string().required(),
    }),
    selectParentHub: Yup.object().shape({
      text: Yup.string().required(),
      id: Yup.string().required(),
    }),
    selectHubType: Yup.object().shape({
      text: Yup.string().required(),
      id: Yup.string().required(),
    }),
    selectState: Yup.object().shape({
      text: Yup.string().required(),
      id: Yup.string().required(),
    }),
    selectCity: Yup.object().shape({
      text: Yup.string().required(),
      id: Yup.string().required(),
    }),
    locationStartDate: Yup.object().required(),
    lat: Yup.number().required(),
    lng: Yup.number().required(),
    // getPlaque: Yup.number().required(),
    // addressDetail: Yup.string().required(),
    addressLine: Yup.string().required(),
  });

export const validationSchemaTypeHub = () =>
  Yup.object({
    name: Yup.string().required(),
    code: Yup.number().required(),
  });

export const validationSchemaLable = () =>
  Yup.object().shape({
    selectHub: Yup.object().shape({
      text: Yup.string().required("الزامی می باشد"),
      id: Yup.string().required("الزامی می باشد"),
    }),
    requestType: Yup.object().shape({
      text: Yup.string().required("الزامی می باشد"),
      id: Yup.string().required("الزامی می باشد"),
    }),
    requestNo: Yup.string().required("الزامی می باشد"),
    requestDate: Yup.object().required(),
    requestedNumber: Yup.string().required("الزامی می باشد"),
  });

export const validationSchemaAllocateLable = () =>
  Yup.object().shape(
    {
      labelRequestDto: Yup.object().shape({
        selectHub: Yup.object().shape({
          text: Yup.string().required("الزامی می باشد"),
          id: Yup.string().required("الزامی می باشد"),
        }),
        requestType: Yup.object().shape({
          text: Yup.string().required("الزامی می باشد"),
          id: Yup.string().required("الزامی می باشد"),
        }),
        requestNo: Yup.string().required("الزامی می باشد"),
        requestDate: Yup.object().required(),
        // requestedNumber: Yup.string().required("الزامی می باشد"),
      }),

      start: Yup.string().required("الزامی می باشد"),
      end: Yup.string().required("الزامی می باشد"),

      selectHub: Yup.object().when("selectCustomer", (val: any) => {
        if (!val) {
          return Yup.object().shape({
            text: Yup.string().required("الزامی می باشد"),
            id: Yup.string().required("الزامی می باشد"),
          });
        } else {
          return Yup.object().shape({
            text: Yup.string().notRequired(),
            id: Yup.string().notRequired(),
          });
        }
      }),

      selectCustomer: Yup.object().when("selectHub", (val: any) => {
        if (val === true) {
          return Yup.string().required();
        } else {
          return Yup.string().notRequired();
        }
      }),
    },
    [
      ["selectHub", "selectCustomer"],
      ["selectCustomer", "selectHub"],
    ]
  );

export const validationSchemaEstesna = () =>
  Yup.object().shape({
    description: Yup.string().required(),
    errorType: Yup.string().required(),
  });

export const validationSchemaCancelTrip = () =>
  Yup.object().shape({
    vehicleNumber0: Yup.string().required(),
    vehicleNumber1: Yup.string().required(),
    vehicleNumber2: Yup.string().required(),
    vehicleNumber3: Yup.string().required(),
  });

export const validationSchemaEndTrip = () =>
  Yup.object().shape({
    endTime: Yup.string().required(),

    vehicleNumber0: Yup.string().required(),
    vehicleNumber1: Yup.string().required(),
    vehicleNumber2: Yup.string().required(),
    vehicleNumber3: Yup.string().required(),
  });

export const validationSchemaEnterBarandaz = () =>
  Yup.object().shape({
    barandaz: Yup.string().required(),
    date: Yup.object().required(),

    vehicleNumber0: Yup.string().required(),
    vehicleNumber1: Yup.string().required(),
    vehicleNumber2: Yup.string().required(),
    vehicleNumber3: Yup.string().required(),
  });

export const validationSchemaEnterDoor = () =>
  Yup.object().shape({
    date: Yup.object().required(),
    //  enterTime:Yup.string().required(),
    selectGate: Yup.object().shape({
      id: Yup.number().required(),
      text: Yup.string().required(),
    }),
    vehicleNumber0: Yup.string().required(),
    vehicleNumber1: Yup.string().required(),
    vehicleNumber2: Yup.string().required(),
    vehicleNumber3: Yup.string().required(),
  });

export const validationSchemaExitBarandaz = () =>
  Yup.object().shape({
    barandaz: Yup.string().required(),
    date: Yup.object().required(),
    vehicleNumber0: Yup.string().required(),
    vehicleNumber1: Yup.string().required(),
    vehicleNumber2: Yup.string().required(),
    vehicleNumber3: Yup.string().required(),
  });

export const validationSchemaExitDoor = () =>
  Yup.object().shape({
    date: Yup.object().shape({
      day: Yup.number().required(),
      month: Yup.number().required(),
      year: Yup.number().required(),
    }),
    selectGate: Yup.object().shape({
      id: Yup.string().required(),
      text: Yup.string().required(),
    }),

    vehicleNumber0: Yup.string().required(),
    vehicleNumber1: Yup.string().required(),
    vehicleNumber2: Yup.string().required(),
    vehicleNumber3: Yup.string().required(),
  });
export const validationSchemaServicePro = () =>
  Yup.object().shape({
    code: Yup.number().required(),
    name: Yup.string().required(),
    service: Yup.object().shape({
      id: Yup.string().required(),
      text: Yup.string().required(),
    }),
    // validDateFrom:Yup.object().required(),
    // validDateTo:Yup.object().required(),
  });

export const vendorValidation = () =>
  Yup.object().shape({
    name: Yup.string().required().label("نام شرکت"),
    code: Yup.string().required().label("کد شرکت"),
    nationalCode: Yup.number().required(),
    contactNumber: Yup.number().label("شماره تماس"),
  });

export const vehicleMakeValidation = () =>
  Yup.object().shape({
    name: Yup.string().required(),
    code: Yup.number().required(),
    weightCapacity: Yup.number().required(),
    volumeCapacity: Yup.number().required(),
    consignmentCapacity: Yup.number().required(),
    vendorSelect: Yup.object(),
    fuelTypeSelect: Yup.object().shape({
      text: Yup.string().required(),
      id: Yup.string().required(),
    }),
  });

export const vehicleValidation = () =>
  Yup.object().shape({
    selecttype: Yup.object().label("نوع وسیله نقلیه"),
    fleetTypeSelect: Yup.object().shape({
      text: Yup.string().required().label("نوع ناوگان"),
      id: Yup.string().required(),
    }),
    selectHub: Yup.object().shape({
      text: Yup.string().required().label("کد هاب"),
      id: Yup.string().required(),
    }),
    vehicleMakeSelect: Yup.object().shape({
      text: Yup.string().required().label("مدل وسیله نقلیه"),
      id: Yup.string().required(),
    }),
    vehicleNumber0: Yup.number().required(),
    vehicleNumber1: Yup.string().required(),
    volumeCapacity: Yup.number().required(),
    weightCapacity: Yup.number().required(),
    // fixedDriverId:Yup.string().required(),
  });

export const routeValidation = () =>
  Yup.object().shape({
    code: Yup.number().required().label("کد مسیر"),
    name: Yup.string().required().label("نام مسیر"),
    selectSourceHub: Yup.object().required().label("مبدا"),
    selectTargetHub: Yup.object().required().label("مقصد"),
    nodes: Yup.number().required().label("گره"),
    connections: Yup.array().of(
      Yup.object().shape({
        distanceFromPreviousHub: Yup.number().required(),
        distanceVariance: Yup.number().required(),
        transitTime: Yup.string().required(),
        timeStoppage: Yup.string().required(),
        selectHub: Yup.object().shape({
          text: Yup.string().required(),
          id: Yup.string().required(),
        }),
      })
    ),
  });

export const gateValidation = () =>
  Yup.object().shape({
    selectHub: Yup.object().shape({
      text: Yup.string().required().label("کد هاب"),
      id: Yup.string().required(),
    }),
    name: Yup.string().required().label("نام درب"),
    code: Yup.number().required().label("کد درب"),
  });

export const exceptionValidation = () =>
  Yup.object().shape({
    code: Yup.string().required(),
    type: Yup.object().shape({
      text: Yup.string().required(),
      id: Yup.string().required(),
    }),
    serviceDeliveryCustomers: Yup.array(),
  });

export const validationSchemaSubTableTwo = () =>
  Yup.object().shape({
    // discountFrom: Yup.number().required(),
    // discountPercent: Yup.number().required(),
    // discountTo: Yup.number().required(),
  });

export const validationSchemaSubTableOne = () =>
  Yup.object().shape({
    // discountFrom: Yup.number().required(),
    // discountPercent: Yup.number().required(),
    // discountTo: Yup.number().required(),
  });

export const validationSchemaVehicleMove = () =>
  Yup.object().shape({
    licenseNo: Yup.string().required(),
    licenseDate: Yup.object().required(),
    validateFrom: Yup.object().required(),
    validateTo: Yup.object().required(),
    selectDestinationHub: Yup.object().required("اجباری است"),
  });

export const validationSchemaProductGroup = () =>
  Yup.object({
    name: Yup.string().required(),
    code: Yup.number().required(),
    description: Yup.string(),
  });

export const dockValidation = () =>
  Yup.object().shape({
    name: Yup.string().required().label("نام بارانداز"),
    code: Yup.number().required().label("کد بارانداز"),
    selectHub: Yup.object().shape({
      text: Yup.string().required().label("کد هاب"),
      id: Yup.string().required(),
    }),
  });

export const bagValidation = () =>
  Yup.object().shape({
    // bagNumber: Yup.number().required("الزامی است"),
    selectBagType: Yup.object().shape({
      text: Yup.string().required("الزامی است"),
      id: Yup.string().required("الزامی است"),
    }),
    selectSourceHub: Yup.object().shape({
      text: Yup.string().required("الزامی است"),
      id: Yup.string().required("الزامی است"),
    }),

    weightCapacity: Yup.string().required("الزامی است"),
    volumeCapacity: Yup.string().when("selectBagType", {
      is: (selectBagType) => selectBagType.text === "کیسه",
      then: Yup.string(),
      otherwise: Yup.string().required("الزامی است"),
    }),
  });
export const productValidation = () =>
  Yup.object().shape({
    code: Yup.string().required("اجباری است"),
    name: Yup.string().required("اجباری است"),
    productGroup: Yup.object().shape({
      text: Yup.string().required("اجباری است"),
      id: Yup.string().required("اجباری است"),
    }),
  });

export const serviceDefineValidation = () =>
  Yup.object().shape({
    name: Yup.string().required("اجباری است"),
    code: Yup.string().required("اجباری است"),
    description: Yup.string().required("اجباری است"),
    minimumOrderQuantity: Yup.number(),
    type: Yup.object().shape({
      text: Yup.string().required("اجباری است"),
      id: Yup.string().required("اجباری است"),
    }),
    product: Yup.object().shape({
      text: Yup.string().required("اجباری است"),
      id: Yup.string().required("اجباری است"),
    }),
    priceList: Yup.object().shape({
      text: Yup.string().required("اجباری است"),
      id: Yup.string().required("اجباری است"),
    }),
  });

export const productInfoalidation = () =>
  Yup.object().shape(
    {
      fromSourceCity: Yup.array().when(
        "fromDestinationCity",
        (val: any, schema: any) => {
          if (val?.length > 0) {
            return Yup.array().required();
          } else {
            return Yup.array().notRequired();
          }
        }
      ),
      fromDestinationCity: Yup.array().when(
        "fromSourceCity",
        (val: any, schema: any) => {
          if (val?.length > 0) {
            return Yup.array().required();
          } else {
            return Yup.array().notRequired();
          }
        }
      ),
      fromDestinationLocation: Yup.array().when(
        "fromSourceLocation",
        (val: any, schema: any) => {
          if (val?.length > 0) {
            return Yup.array().required();
          } else {
            return Yup.array().notRequired();
          }
        }
      ),
      fromSourceLocation: Yup.array().when(
        "fromDestinationLocation",
        (val: any, schema: any) => {
          if (val) {
            return Yup.array().required();
          } else {
            return Yup.array().notRequired();
          }
        }
      ),
      fromCountryDevision: Yup.array(),
      toCountryDevision: Yup.array(),
      usingProduct: Yup.array(),
      timeCommitment: Yup.object().shape({
        text: Yup.string().required(),
        id: Yup.string().required(),
      }),
      product: Yup.object().shape({
        text: Yup.string().required(),
        id: Yup.string().required(),
      }),
      toWeight: Yup.number().min(0),
      fromWeight: Yup.number()
        .min(0)
        .when("toWeight", (val: any, schema: any) => {
          if (val?.length > 0) {
            return Yup.number().nullable().required();
          } else {
            return Yup.number().notRequired();
          }
        }),
      fromDim: Yup.number()
        .min(0)
        .when("toDimension", (val: any, schema: any) => {
          if (val?.length > 0) {
            return Yup.number().nullable().required();
          } else {
            return Yup.number().notRequired();
          }
        }),
      toDimension: Yup.number().min(0),
      fromValue: Yup.number()
        .min(0)
        // .ensure()
        .when("toValue", (val: any, schema: any) => {
          if (val?.length > 0) {
            return Yup.number().nullable().required();
          } else {
            return Yup.number().notRequired();
          }
        }),
      toValue: Yup.number().min(0),
    },
    [
      ["fromSourceLocation", "fromDestinationLocation"],
      ["fromSourceCity", "fromDestinationCity"],
      ["fromWeight", "toWeight"],
      ["toDimension", "fromDim"],
      ["fromValue", "toValue"],
    ]
  );

export const driverChangeStatusValidation = () =>
  Yup.object().shape({
    fromTime: Yup.array().of(
      Yup.object().shape({
        presenceAbsenceTimeFrom: Yup.string().required().label("اجباری است"),
      })
    ),
    toTime: Yup.array().of(
      Yup.object().shape({
        presenceAbsenceTimeTo: Yup.string().required().label("اجباری است"),
      })
    ),
    shift: Yup.array().of(
      Yup.object().shape({
        selectdispatchShift: Yup.array().of(
          Yup.object().shape({
            id: Yup.string().required(),
            text: Yup.string().required(),
          })
        ),
      })
    ),
  });

export const driverManagmentvalidation = () =>
  Yup.object().shape({
    selectHub: Yup.object().shape({
      text: Yup.string().required("اجباری است"),
      id: Yup.string().required("اجباری است"),
    }),
    startDate: Yup.object().required("اجباری است"),
    endDate: Yup.object().required("اجباری است"),
    drivingLicenceNumber: Yup.number().required("اجباری است"),
    drivingLicenseExpiry: Yup.object().required("اجباری است"),
    name: Yup.string().required("اجباری است"),
    mobile: Yup.number().required("اجباری است"),
  });

export const sampleShiftvalidation = () =>
  Yup.object().shape({
    selecthub: Yup.object().shape({
      text: Yup.string().required("اجباری است"),
      id: Yup.string().required("اجباری است"),
    }),
    year: Yup.object().shape({
      text: Yup.string().required("اجباری است"),
      id: Yup.string().required("اجباری است"),
    }),
    dispatchShiftType: Yup.object().shape({
      text: Yup.string().required("اجباری است"),
      id: Yup.string().required("اجباری است"),
    }),
    timeFrom: Yup.string(),
    timeTo: Yup.string(),
  });

export const addCaseShiftvalidation = () =>
  Yup.object().shape({
    timeFrom: Yup.string().required("اجباری است"),
    timeTo: Yup.string().required("اجباری است"),
  });
export const saleChannelValidation = () =>
  Yup.object().shape({
    name: Yup.string().required().label("نام شرکت"),
    code: Yup.string().required().label("کد شرکت"),
  });

export const validationAddPerson = () =>
  Yup.object().shape({
    personelCode: Yup.string().required(),
    nationalCode: Yup.string()
      .matches(NationalCodeRegex, VALIDNATIONALCODE)
      .required(),
    name: Yup.string().required(),
    mobile: Yup.string().matches(MobileRegex, VALIDMOBILE).required(),
    email: Yup.string().email(),
    username: Yup.string()
      .matches(JustEngNameRegex, VALIDLATINUSERNAME)
      .required(),
    password: Yup.string()
      .matches(ComplexPasswordRegex, VALIDCOMPLEXREGEX)
      .required(),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], UNMATCHPASSWORD)
      .required(),
    isSuperAdmin: Yup.object().shape({
      text: Yup.string().required(),
      id: Yup.string().required(),
    }),
  });
export const validationEditPerson = () =>
  Yup.object().shape({
    personelCode: Yup.string().required(),
    nationalCode: Yup.string()
      .matches(NationalCodeRegex, VALIDNATIONALCODE)
      .required(),
    name: Yup.string().required(),
    mobile: Yup.string().matches(MobileRegex, VALIDMOBILE).required(),
    email: Yup.string().email(),

    isSuperAdmin: Yup.object().shape({
      text: Yup.string().required(),
      id: Yup.string().required(),
    }),
  });

export const validationAddRole = () =>
  Yup.object().shape({
    name: Yup.string().required("این فیلد اجبرای است"),
  });

export const changePasswordValidation = () =>
  Yup.object().shape({
    password: Yup.string()
      .min(8)
      .matches(PasswordRegex, VALIDENGPASSWORD)
      .required(),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], UNMATCHPASSWORD)
      .required(),
  });

export const ADMVehicleFormValidation = () =>
  Yup.object().shape({
    vehicleNumber0: Yup.string().required(),
    vehicleNumber1: Yup.string().required(),
    vehicleNumber2: Yup.string().required(),
    vehicleNumber3: Yup.string().required(),
    dayToStartWork: Yup.object().shape({
      day: Yup.number().required(),
      month: Yup.number().required(),
      year: Yup.number().required(),
    }),
    dayToFinishWork: Yup.object().shape({
      day: Yup.number().required(),
      month: Yup.number().required(),
      year: Yup.number().required(),
    }),
    timeToStartWork: Yup.string().required(),
    timeToFinishWork: Yup.string().required(),
    selectHub: Yup.object().shape({
      text: Yup.string().required(),
      id: Yup.string().required(),
    }),
    vehicleMakeSelect: Yup.object().shape({
      text: Yup.string().required(),
      id: Yup.string().required(),
    }),
    volumeCapacity: Yup.number().required(),
    weightCapacity: Yup.number().required(),
    name: Yup.string(),
    code: Yup.string(),
    isActive: Yup.boolean(),
  });

export const personRole = () =>
  Yup.object().shape({
    name: Yup.string(),
    role: Yup.array().required("مقادیر نباید خالی باشد"),
  });
export const personRoleUser = () =>
  Yup.object().shape({
    users: Yup.array(),
    role: Yup.array().required("مقادیر نباید خالی باشد"),
  });

export const CustomerAddValidation = (customerTypeId: number | string) =>
  Yup.object().shape({
    // code: Yup.string().required(),
    name: Yup.string().required(),
    mobile: Yup.string()
      .matches(/^(09\d{9}|9\d{9})$/, VALIDMOBILE)
      .required(),
    selectCustomerType: Yup.object().shape({
      text: Yup.string().required(),
      id: Yup.string().required(),
    }),
    selectParentCustomer: Yup.object().nullable(true).shape({
      text: Yup.string(),
      id: Yup.number(),
    }),
    email: Yup.string().email(),
    currentCredit: Yup.number(),
    creditLimit: Yup.number(),
    initialCredit: Yup.number(),
    username:
      customerTypeId === 0
        ? Yup.string().nullable(true)
        : Yup.string().required(),
    password:
      customerTypeId === 0
        ? Yup.string().nullable(true)
        : Yup.string()
            .min(8)
            .matches(JustEngPasswordRegex, VALIDENGPASSWORD)
            .required(),
    confirmPassword:
      customerTypeId === 0
        ? Yup.string().nullable(true)
        : Yup.string()
            .oneOf([Yup.ref("password"), null], UNMATCHPASSWORD)
            .required(),
    extendGlobalVirtualSeries: Yup.boolean().nullable(),
    dynamicPickupAllocation: Yup.boolean().nullable(),
    smsNotification: Yup.boolean().nullable(),
    emailNotification: Yup.boolean().nullable(),
    pickupPaperWithEmail: Yup.boolean().nullable(),
    isActive: Yup.boolean().nullable(),

    postPaidValidFrom: Yup.object().when("withPostPaid", (val: any) => {
      if (val) {
        return Yup.object().shape({
          day: Yup.string().required(),
        });
      } else {
        return Yup.object().shape({
          day: Yup.string().notRequired(),
        });
      }
    }),
    postPaidValidTo: Yup.object().when("withPostPaid", (val: any) => {
      if (val) {
        return Yup.object().shape({
          day: Yup.string().required(),
        });
      } else {
        return Yup.object().shape({
          day: Yup.string().notRequired(),
        });
      }
    }),
    codValidFrom: Yup.object().when("withCod", (val: any) => {
      if (val) {
        return Yup.object().shape({
          day: Yup.string().required(),
        });
      } else {
        return Yup.object().shape({
          day: Yup.string().notRequired(),
        });
      }
    }),
    codValidTo: Yup.object().when("withCod", (val: any) => {
      if (val) {
        return Yup.object().shape({
          day: Yup.string().required(),
        });
      } else {
        return Yup.object().shape({
          day: Yup.string().notRequired(),
        });
      }
    }),
  });

export const CustomerEditValidation = (customerTypeId) =>
  Yup.object().shape({
    // code: Yup.string().required(),
    name: Yup.string().required(),
    mobile: Yup.number().required(),
    selectCustomerType: Yup.object().shape({
      text: Yup.string().required(),
      id: Yup.string().required(),
    }),
    selectParentCustomer: Yup.object().nullable(true).shape({
      text: Yup.string(),
      id: Yup.number(),
    }),
    email: Yup.string().email(),
    currentCredit: Yup.number().nullable(),
    creditLimit: Yup.number().nullable(),
    initialCredit: Yup.number().nullable(),
    username:
      customerTypeId === 0
        ? Yup.string().nullable(true)
        : Yup.string().required(),
    extendGlobalVirtualSeries: Yup.boolean().nullable(),
    dynamicPickupAllocation: Yup.boolean().nullable(),
    smsNotification: Yup.boolean().nullable(),
    emailNotification: Yup.boolean().nullable(),
    pickupPaperWithEmail: Yup.boolean().nullable(),
    isActive: Yup.boolean().nullable(),
  });

export const CustomerAddressValidation = () =>
  Yup.object().shape({
    selectAddressType: Yup.object().shape({
      text: Yup.string().required(),
      id: Yup.string().required(),
    }),
    selectCity: Yup.object().shape({
      text: Yup.string().required(),
      id: Yup.string().required(),
    }),
    addressDetail: Yup.string().required(),
    postalCode: Yup.string()
      .matches(PostalCodeRegex, VALIDPOSTALCODE)
      .nullable(true),
    lat: Yup.string().required(),
    lng: Yup.string().required(),
    address: Yup.string().required(),
  });

export const TelephoneValidation = () =>
  Yup.object().shape({
    selectPhoneType: Yup.object().shape({
      text: Yup.string().required(),
      id: Yup.string().required(),
    }),
    telNumber: Yup.number().required(),
    telephonePrefix: Yup.number().when("selectPhoneType", (val: any) => {
      if (val.id === "1") {
        return Yup.number().nullable(true);
      } else {
        return Yup.number().required();
      }
    }),
  });

export const ServiceTimeValidation = () =>
  Yup.object().shape({
    name: Yup.string().required(),
    from: Yup.number().min(0).required(),
    to: Yup.number()
      .min(0)
      .test(
        "isLarger",
        "باید از مقدار از بزرگتر باشد",
        (value, testContext) => {
          if (testContext.parent.from > value) return false;
          return true;
        }
      )
      .required(),
    isActive: Yup.boolean().nullable(),
    description: Yup.string().nullable(),
    selecttedtimeUnit: Yup.object().shape({
      text: Yup.string().required(),
      id: Yup.string().required(),
    }),
  });
// Yup.number().min(0).required()
export const ThirdPartyValidation = () =>
  Yup.object().shape({
    code: Yup.string().required(),
    name: Yup.string().required(),
    selectThirdPartyType: Yup.object().shape({
      text: Yup.string().required(),
      id: Yup.string().required(),
    }),
    selectThirdPartyCategory: Yup.object().nullable(true).shape({
      text: Yup.string(),
      id: Yup.number(),
    }),
    selectParentThirdParty: Yup.object().nullable(true).shape({
      text: Yup.string(),
      id: Yup.number(),
    }),
    email: Yup.string().email(),
    isActive: Yup.boolean().nullable(),
  });

export const AddressValidation = () =>
  Yup.object().shape({
    selectCity: Yup.object().shape({
      text: Yup.string().required(),
      id: Yup.string().required(),
    }),
    postalCode: Yup.string()
      .matches(PostalCodeRegex, VALIDPOSTALCODE)
      .nullable(true),
    addressDetail: Yup.string().required("جزیئات را پر کنید."),
    lat: Yup.string().required("موقعیت مکانی را روی نقشه مشخص کنید"),
    lng: Yup.string().required("موقعیت مکانی را روی نقشه مشخص کنید"),
    address: Yup.string().required(),
    staticAddress: Yup.string().notRequired(),
  });

export const PriceValidation = () =>
  Yup.object().shape({
    priceListCode: Yup.number().required(),
    priceListName: Yup.string().required(),
    priceListDate: Yup.object().required(),
    validDateFrom: Yup.object().required(),
    validDateTo: Yup.object().required(),
  });

export const PriceAttributeValidation = () =>
  Yup.object().shape(
    {
      product: Yup.object().shape({
        id: Yup.string().required(),
      }),
      isParametric: Yup.boolean(),
      fromCountryDevision: Yup.string()
        .ensure()
        .when("categoryType", (val: any) => {
          if (val?.id === "0") {
            return Yup.array().min(1, REQUIRED).required();
          } else {
            return Yup.array().nullable(true);
          }
        }),
      toCountryDevision: Yup.string()
        .ensure()
        .when("categoryType", (val: any) => {
          if (val?.id === "0") {
            return Yup.array().min(1, REQUIRED).required();
          } else {
            return Yup.array().nullable(true);
          }
        }),

      fromSourceCity: Yup.array().when(
        "fromDestinationCity",
        (val: any, schema: any) => {
          if (val?.length > 0) {
            return Yup.array().required();
          } else {
            return Yup.array().notRequired();
          }
        }
      ),
      fromDestinationCity: Yup.array().when(
        "fromSourceCity",
        (val: any, schema: any) => {
          if (val?.length > 0) {
            return Yup.array().required();
          } else {
            return Yup.array().notRequired();
          }
        }
      ),
      // fromDestinationLocation: Yup.array().when(
      //   "fromSourceLocation",
      //   (val: any) => {
      //     if (val?.length > 0) {
      //       return Yup.array().required();
      //     } else {
      //       return Yup.array().notRequired();
      //     }
      //   }
      // ),
      // fromSourceLocation: Yup.array().when(
      //   "fromDestinationLocation",
      //   (val: any) => {
      //     if (val) {
      //       return Yup.array().required();
      //     } else {
      //       return Yup.array().notRequired();
      //     }
      //   }
      // ),
      categoryType: Yup.bool().when("isParametric", (val: any) => {
        if (val === false) {
          return Yup.object().shape({
            id: Yup.string().required(),
          });
        } else
          return Yup.object().nullable().shape({
            id: Yup.string().nullable(),
          });
      }),
      customDevision: Yup.object().when(["isParametric", "categoryType"], {
        is: (isParametric: any, categoryType: any) =>
          isParametric === false && categoryType.id === "1",
        then: Yup.object().shape({
          id: Yup.string().required(),
        }),
      }),
      price: Yup.number()
        .min(0)
        .when("fixedPrice", {
          is: false,
          then: Yup.number().nullable(true).default(null).required(),
        }),
      fromWeight: Yup.number().min(0).nullable(true),
      toWeight: Yup.number().min(0).max(25).nullable(true),
      fromValue: Yup.number().min(0).nullable(true),
      toValue: Yup.number().min(0).nullable(true),
      fromNumber: Yup.number().min(0).nullable(true),
      toNumber: Yup.number().min(0).nullable(true),
    },
    [
      ["fromSourceCity", "fromDestinationCity"],
      // ["fromSourceLocation", "fromDestinationLocation"],
      ["fromWeight", "isParametric"],
      ["toWeight", "isParametric"],
      ["fromNumber", "isParametric"],
      ["toNumber", "isParametric"],
      ["isParametric", "toValue"],
      ["isParametric", "fromValue"],
      ["price", "fixedPrice"],
    ]
  );

export const AssignmentValidation = () =>
  Yup.object().shape({
    selectfirsdriver: Yup.object().shape({
      text: Yup.string().required(),
      id: Yup.string().required(),
    }),
  });

export const ConsignmentDetailsValidation = (type: any) =>
  type === 2
    ? Yup.object().shape({
        declarativeWeight: Yup.number()
          .max(25)
          .required()
          .test("Is positive?", VALIDATEREQNUMBER, (value) => value > 0),
      })
    : Yup.object().shape({
        selectContent: Yup.object().shape({
          text: Yup.string().required(),
          id: Yup.string().required(),
        }),
        content: Yup.object().when("selectContent", (val: any) => {
          if (val.id === "0")
            return Yup.object().shape({
              id: Yup.string().nullable(true),
            });
          else
            return Yup.object().shape({
              text: Yup.string().required(),
              id: Yup.string().required(),
            });
        }),
        contentofOthers: Yup.object().when("content", (val: any) => {
          if (val?.id === "21") return Yup.string().required();
          else return Yup.string().nullable(true);
        }),

        declarativeLength: Yup.number()
          .required()
          .max(100)
          .test("Is positive?", VALIDATEREQNUMBER, (value) => value > 0),
        declarativeWidth: Yup.number()
          .required()
          .max(100)
          .test("Is positive?", VALIDATEREQNUMBER, (value) => value > 0),
        declarativeHeight: Yup.number()
          .required()
          .max(100)
          .test("Is positive?", VALIDATEREQNUMBER, (value) => value > 0),
        declarativeWeight: Yup.number()
          .required()
          .max(25)
          .test("Is positive?", VALIDATEREQNUMBER, (value) => value > 0),
        numberOfPieces: Yup.object().when("selectContent", (val: any) => {
          if (val.id === "0") return Yup.string().nullable(true);
          else
            return Yup.number()
              .required()
              .test("Is positive?", VALIDATEREQNUMBER, (value) => value > 0);
        }),
        declarativeValue: Yup.number()
          .required()
          .test("Is positive?", VALIDATEREQNUMBER, (value) => value > 0),
        costOfGoods: Yup.number().when(
          "needToReciveCostOfGoods",
          (val: any) => {
            if (val === false) {
              return Yup.number().nullable();
            } else {
              return Yup.number()
                .required()
                .test("Is positive?", VALIDATEREQNUMBER, (value) => value > 0);
            }
          }
        ),

        typeOfPackage: Yup.object().shape({
          text: Yup.string().required(),
          id: Yup.string().required(),
        }),

        // typeOfPackage: Yup.number().when("needToPack", (val: any) => {
        //   if (val === false) {
        //     return Yup.number().nullable();
        //   } else {
        //     return Yup.object().shape({
        //       id: Yup.string().required(),
        //     });
        //   }
        // }),
      });

export const RecieverCustomerValidation = () =>
  Yup.object().shape({
    name: Yup.string().trim().min(2).required(),
    mobile: Yup.string().required(),
  });
export const RecieverMobileValidation = () =>
  Yup.object().shape({
    telNumber: Yup.string().required(),
  });

export const EditCustomerPhoneValidation = () =>
  Yup.object().shape({
    telNumber: Yup.number().required(),
  });

export const createTripValidation = () =>
  Yup.object().shape({
    selectdestinationHub: Yup.object().when("triptype", (val: any) => {
      if (val.id === "3") {
        return Yup.object().shape({
          text: Yup.string().required(),
          id: Yup.string().required(),
        });
      } else {
        return Yup.object().shape({
          id: Yup.string().nullable(true),
        });
      }
    }),
    selectRoute: Yup.object().when("triptype", (val: any) => {
      if (val.id === "3") {
        return Yup.object().shape({
          text: Yup.string().required(),
          id: Yup.string().required(),
        });
      } else {
        return Yup.object().shape({
          id: Yup.string().nullable(true),
        });
      }
    }),
    selectdispatchShift: Yup.object().shape({
      text: Yup.string().required(),
      id: Yup.string().required(),
    }),
    triptype: Yup.object().shape({
      text: Yup.string().required(),
      id: Yup.string().required(),
    }),
    loading: Yup.string().required(),
    capacityCompletionPercentage: Yup.string().required(),
    vehiclePlanDate: Yup.object().shape({
      year: Yup.number().required(),
      month: Yup.number().required(),
      day: Yup.number().required(),
    }),
  });

export const CreateNewTripValidation = () =>
  Yup.object().shape({
    vehicleNumber0: Yup.string().required(),
    vehicleNumber1: Yup.string().required(),
    vehicleNumber2: Yup.string().required(),
    vehicleNumber3: Yup.string().required(),
    vehicleMakeSelect: Yup.object().shape({
      text: Yup.string().required(),
      id: Yup.string().required(),
    }),
    selectdestinationHub: Yup.object().when("triptype", (val: any) => {
      if (val.id === "3") {
        return Yup.object().shape({
          text: Yup.string().required(),
          id: Yup.string().required(),
        });
      } else {
        return Yup.object().shape({
          id: Yup.string().nullable(true),
        });
      }
    }),
    selectRoute: Yup.object().when("triptype", (val: any) => {
      if (val.id === "3") {
        return Yup.object().shape({
          text: Yup.string().required(),
          id: Yup.string().required(),
        });
      } else {
        return Yup.object().shape({
          id: Yup.string().nullable(true),
        });
      }
    }),
    driver: Yup.string().required(),
    mobile: Yup.number().required(),
    drivingLicenceNumber: Yup.number().required(),
    selectdispatchShift: Yup.object().shape({
      text: Yup.string().required(),
      id: Yup.string().required(),
    }),
    triptype: Yup.object().shape({
      text: Yup.string().required(),
      id: Yup.string().required(),
    }),
    loading: Yup.string().required(),
    capacityCompletionPercentage: Yup.string().required(),
    drivingLicenseExpiry: Yup.object().shape({
      year: Yup.number().required(),
      month: Yup.number().required(),
      day: Yup.number().required(),
    }),
    vehiclePlanDate: Yup.object().shape({
      year: Yup.number().required(),
      month: Yup.number().required(),
      day: Yup.number().required(),
    }),
  });

export const RecoveryValidation = () =>
  Yup.object().shape({
    password: Yup.string()
      .min(8)
      .matches(JustEngPasswordRegex, VALIDENGPASSWORD)
      .required(),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], UNMATCHPASSWORD)
      .required(),
  });

export const ForgotPasswordValidation = () =>
  Yup.object().shape({
    username: Yup.string().required(),
  });

export const DeliveryRegionAllocatedValidation = () =>
  Yup.object().shape({
    selectRegion: Yup.object().shape({
      text: Yup.string().required(),
      id: Yup.string().required(),
    }),
  });

export const CheckCityValidation = () =>
  Yup.object().shape({
    selectCity: Yup.object().shape({
      text: Yup.string().required(),
      id: Yup.string().required(),
    }),
  });
export const validationpinCode = Yup.object().shape({});
export const validationAddEditCrmManagement = Yup.object().shape({
  name: Yup.string().required(),
  code: Yup.string().required(),
  selectcustomer: Yup.array(),
});
export const validationTitle = Yup.object().shape({
  name: Yup.string().required(),
  code: Yup.number().required(),
});

export const validationGeowrapper = Yup.object().shape(
  {
    fromCountryDevision: Yup.array().required(),
    toCountryDevision: Yup.array().required(),

    fromSourceCity: Yup.array().when("fromDestinationCity", (val: any) => {
      if (val?.length > 0) {
        return Yup.array().required();
      } else {
        return Yup.array().notRequired();
      }
    }),
    fromDestinationCity: Yup.array().when("fromSourceCity", (val: any) => {
      if (val?.length > 0) {
        return Yup.array().required();
      } else {
        return Yup.array().notRequired();
      }
    }),
    fromDestinationLocation: Yup.array().when(
      "fromSourceLocation",
      (val: any) => {
        if (val?.length > 0) {
          return Yup.array().required();
        } else {
          return Yup.array().notRequired();
        }
      }
    ),
    fromSourceLocation: Yup.array().when(
      "fromDestinationLocation",
      (val: any) => {
        if (val) {
          return Yup.array().required();
        } else {
          return Yup.array().notRequired();
        }
      }
    ),
  },
  [
    ["fromSourceLocation", "fromDestinationLocation"],
    ["fromSourceCity", "fromDestinationCity"],
  ]
);
export const geowrapperEdittitle = () =>
  Yup.object().shape({
    name: Yup.string().required(),
    code: Yup.number().required(),
  });

export const validationlocationForm = () =>
  Yup.object().shape(
    {
      fromCountryDevision: Yup.array().required(),
      toCountryDevision: Yup.array().required(),

      fromSourceCity: Yup.array().when("fromDestinationCity", (val, schema) => {
        if (val?.length > 0) {
          return Yup.array().required();
        } else {
          return Yup.array().notRequired();
        }
      }),
      fromDestinationCity: Yup.array().when("fromSourceCity", (val, schema) => {
        if (val?.length > 0) {
          return Yup.array().required();
        } else {
          return Yup.array().notRequired();
        }
      }),
      fromDestinationLocation: Yup.array().when(
        "fromSourceLocation",
        (val: any, schema: any) => {
          if (val?.length > 0) {
            return Yup.array().required();
          } else {
            return Yup.array().notRequired();
          }
        }
      ),
      fromSourceLocation: Yup.array().when(
        "fromDestinationLocation",
        (val: any, schema: any) => {
          if (val) {
            return Yup.array().required();
          } else {
            return Yup.array().notRequired();
          }
        }
      ),
    },
    [
      ["fromSourceLocation", "fromDestinationLocation"],
      ["fromSourceCity", "fromDestinationCity"],
    ]
  );
export const valicationCreateEditZone = () =>
  Yup.object().shape({
    code: Yup.string().required(),
    name: Yup.string().required(),
    countrytype: Yup.object().shape({
      text: Yup.string().required(),
      id: Yup.string().required(),
    }),
    employee: Yup.object().shape({
      text: Yup.string().required(),
      id: Yup.string().required(),
    }),
  });
export const valicationCreateHubzoneInfoForm = () =>
  Yup.object().shape({
    code: Yup.string().required(),
    name: Yup.string().required(),
    countrytype: Yup.object().shape({
      text: Yup.string().required(),
      id: Yup.string().required(),
    }),
    employee: Yup.object().shape({
      text: Yup.string().required(),
      id: Yup.string().required(),
    }),
  });
export const valicationCreateEdizoneForm = () =>
  Yup.object().shape({
    code: Yup.string().required(),
    name: Yup.string().required(),
  });

export const ConsignmentsPaymentValidation = () =>
  Yup.object().shape({
    consignments: Yup.array().of(
      Yup.object().shape({
        payBy: Yup.string().required(),
      })
    ),
  });

export const programmingValidation = () =>
  Yup.object().shape({
    selectHub: Yup.object().shape({
      text: Yup.string().required(),
      id: Yup.string().required(),
    }),
    pickupShift: Yup.object().shape({
      text: Yup.string().required(),
      id: Yup.string().required(),
    }),
    mdlShift: Yup.object().shape({
      text: Yup.string().required(),
      id: Yup.string().required(),
    }),
    selectVehicle: Yup.array().required(),
    mdlVehicle: Yup.array().required(),
  });

export const planingValidation = () =>
  Yup.object().shape({
    selectHub: Yup.object().shape({
      text: Yup.string().required(),
      id: Yup.string().required(),
    }),
    pickupShift: Yup.object().shape({
      text: Yup.string().required(),
      id: Yup.string().required(),
    }),
    mdlShift: Yup.object().shape({
      text: Yup.string().required(),
      id: Yup.string().required(),
    }),
  });

export const assignVehiclePlaningValidation = () =>
  Yup.object().shape({
    pudoVehicleId: Yup.object().shape({
      text: Yup.string().required(),
      id: Yup.string().required(),
    }),
  });

export const deliveredModalValidation = Yup.object({
  // deliveryPersonName: Yup.string().required(
  //   "وارد کردن نام و نام خانوادگی الزامی است."
  // ),
  // deliveryPersonNationalCode: Yup.string()
  //   .matches(NationalCodeRegex, VALIDNATIONALCODE)
  //   .required("وارد کردن کد ملی الزامی است"),
  // deliveryPersonMobile: Yup.string().required(
  //   "وارد کردن شماره موبایل الزامی است"
  // ),
  deliveryCode: Yup.string().required("  الزامی است"),
});

export const addOrderValidation = (hasSneder) =>
  Yup.object({
    receiver: Yup.object().shape({
      name: Yup.string()
        .trim()
        .min(2)
        .matches(SpecialCharacter, NOTTYPE)
        .required(),
      mobile: Yup.string().matches(mobileReg, NOTTYPE).required(),
      receiverCityId: Yup.object().shape({
        text: Yup.string().required(),
        id: Yup.string().required(),
      }),
      address: Yup.string()
        .trim()
        .min(20)
        .matches(SpecialCharacter, NOTTYPE)
        .required(),
    }),

    sender:
      !hasSneder &&
      Yup.object().shape({
        name: Yup.string()
          .trim()
          .min(2)
          .matches(SpecialCharacter, NOTTYPE)
          .required(),
        // nationalCode: Yup.string().required(),
        mobile: Yup.string().matches(mobileReg, NOTTYPE).required(),
      }),
    width: Yup.number().required(),
    height: Yup.number().required(),
    length: Yup.number().required(),
    weight: Yup.number().min(0).max(30).required(),
    cprNumber: Yup.number().required(),
    contentType: Yup.object().shape({
      text: Yup.string().required(),
      id: Yup.string().required(),
    }),
    packageId: Yup.object().shape({
      text: Yup.string().required(),
      id: Yup.string().required(),
    }),
    extraCosts: Yup.number().max(1500000).nullable(),
    value: Yup.object().when("insurance", (val: any) => {
      if (val.id === 1) {
        return Yup.number().min(1000001).max(200000000).required();
      } else {
        return Yup.number().notRequired();
      }
    }),
    costOfGoods: Yup.object().when("cod", (val: any) => {
      if (val === true) {
        return Yup.number().required();
      } else {
        return Yup.number().notRequired();
      }
    }),
  });

export const postDelieveryValidation = () =>
  Yup.object().shape({
    selectHub: Yup.object().shape({
      id: Yup.string(),
      text: Yup.string().required("اجباری است"),
    }),
    fromDate: Yup.object().shape({
      day: Yup.string().required(),
      month: Yup.string().required(),
      year: Yup.string().required(),
    }),
    toDate: Yup.object().shape({
      day: Yup.string().required(),
      month: Yup.string().required(),
      year: Yup.string().required(),
    }),
  });

export const postDelieveryByScanValidation = () =>
  Yup.object().shape({
    selectHub: Yup.object().shape({
      id: Yup.string(),
      text: Yup.string().required("اجباری است"),
    }),
  });
export const changeDriverValidation = () =>
  Yup.object().shape({
    selectHub: Yup.object().shape({
      id: Yup.string(),
      text: Yup.string().required("اجباری است"),
    }),
    selectTripType: Yup.object().shape({
      id: Yup.string(),
      text: Yup.string().required("اجباری است"),
    }),
    tripNo: Yup.object().shape({
      id: Yup.string(),
      text: Yup.string().required("اجباری است"),
    }),
    selectDriver: Yup.object().shape({
      id: Yup.string(),
      text: Yup.string().required("اجباری است"),
    }),
  });

export const consignmentAddressValidation = () =>
  Yup.object().shape({
    selectCity: Yup.object().shape({
      text: Yup.string().required(),
      id: Yup.string().required(),
    }),
    postalCode: Yup.string()
      .matches(PostalCodeRegex, VALIDPOSTALCODE)
      .nullable(true),
    addressDetail: Yup.string().required("جزیئات را پر کنید."),
    lat: Yup.string().required("موقعیت مکانی را روی نقشه مشخص کنید"),
    lng: Yup.string().required("موقعیت مکانی را روی نقشه مشخص کنید"),
    address: Yup.string().notRequired(),
    staticAddress: Yup.string().notRequired(),
  });

export const creditValidation = () =>
  Yup.object().shape({
    amount: Yup.number().min(0).required(),
  });

export const orderReceiptValidate = () =>
  Yup.object().shape({
    nationalCode: Yup.string().required(),
    name: Yup.string().required(),
    mobile: Yup.string().required(),
    count: Yup.string().required(),
  });

export const deliveryGroupValidate = () =>
  Yup.object().shape({
    selectDriver: Yup.object().shape({
      text: Yup.string().required(),
      id: Yup.string().required(),
    }),

    // dayToStartWork: Yup.object().shape({
    //   day: Yup.number().required(),
    //   month: Yup.number().required(),
    //   year: Yup.number().required(),
    // }),
    deliveryTime: Yup.string().required(),
  });
export const deliveryGroupPrintValidate = () =>
  Yup.object().shape({
    // selectDriver:Yup.array().required("اجباری است"),
    selectDriver: Yup.object().shape({
      text: Yup.string().required(),
      id: Yup.string().required(),
    }),

    deliveryTime: Yup.string().required(),
  });

export const defineTerminalValidation = () =>
  Yup.object().shape({
    terminalNo: Yup.string().required(),
    hubId: Yup.object().shape({
      text: Yup.string().required(),
      id: Yup.string().required(),
    }),
    // employeeId: Yup.object().shape({
    //   text: Yup.string().required(),
    //   id: Yup.string().required(),
    // }),
    isActive: Yup.object().shape({
      text: Yup.string().required(),
      id: Yup.string().required(),
    }),
  });

export const allocateHubCredit = () =>
  Yup.object().shape({
    maxCredit: Yup.number().required(),
  });
