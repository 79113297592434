import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { setCustomerId, setIsCustomerHasCode, setOTP, setOtpResponse } from "redux/otp";
import { addEditDataAPI } from "services/CRUDServices";

function useVerifyOTP(setOpen, mobile,extraData) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { otpCode } = useSelector((state: any) => state.OTP);
  async function verifyOTP() {
    try {
      setLoading(true);
     const {data}= await addEditDataAPI(
        `/customer-api/customer-management/customers/otp/${mobile}/customer-code-create-verify`,
        "post",
        {
          customerData: {
            ...extraData,
            code: undefined,
         
          },
          otpCode: otpCode,
        }
      );
      dispatch(setIsCustomerHasCode(true));
      dispatch(setCustomerId(data.response.id));
      dispatch(setOtpResponse(data.response.code));
      setLoading(false);
      setOpen((prev) => ({
        ...prev,
        OTPModal: false,
        verificationModal: false,
      }));
    } catch (e) {
      setLoading(false);
      // toast.error(e?.response?.data?.errors?.[0]?.message);
    }
  }

  return { verifyOTP, loading };
}

export default useVerifyOTP;
