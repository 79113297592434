import { AsyncThunk, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { filterUrls } from "../../services/api.enums";
import { filterDataAPI } from "./../../services/CRUDServices";

export const customerData: AsyncThunk<{}, {}, {}> = createAsyncThunk(
  "customerList",
  async (body: any) => {

    let { data } = await filterDataAPI(
      filterUrls.newCustomer,

      {
        pagination: {
          page: body.pageNumber,
          size: 10,
          sorts: [
            {
              property: "id",
              direction: "DESC",
            },
          ],
        },
        searchCriteria: {
          code: body.code || null,
          name: body.name || null,
          mobile: body.telNumber || null,
          postalCode:body.postalCode || null,
          isActive:body.isActive,
          parentCustomerId:body?.selectParentCustomer?.id || undefined,
          address:body?.address || null,
          fetchParent: true,
          fetchAddresses: true,
          // fetchRelatedAddresses: true,
          fetchTelephones: true,
          // fetchChildren: true,
        },
      },
      true,
      false
    );

  

    return data.response;
  }
);

const initialState: any = {
  customerList: [],
  fetchpost: false,
  errorMessage: null,
  filter: {},
};

const CustomerList = createSlice({
  initialState: initialState,
  name: "customerList",
  reducers: {
    clearCustomer: (state) => {
      state.customerList = [];
    },
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
  },
  extraReducers: {
    [customerData.fulfilled as any]: (state, action) => {
      state.customerList = action.payload;
      state.fetchPost = false;
    },
    [customerData.pending as any]: (state) => {
      state.fetchPost = true;
    },
    [customerData.rejected as any]: (state) => {
      state.fetchPost = false;
      state.errorMessage = "wrong";
    },
  },
});

export const { setFilter, clearCustomer } = CustomerList.actions;
export default CustomerList.reducer;
