import AutocompleteInput from "global/Autocomplete/AutocompleteInput";
import InputText from "global/InputText/InputText";
import OTP from "global/otp";
import { useEffect, useRef } from "react";
import { IoCloseSharp } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { clearOTP } from "redux/otp";
import { ReactComponent as Tick } from "../../../assets/icons/trueTick.svg";
import { RenderIcon } from "../components/render-icon";
import useFetchIput from "../hooks/useFetchIput";
import useHandleKeyDown from "../hooks/useHandleKeyDown";
import { useSpeechRecognize } from "../hooks/useSpeech-recognize";
interface OrderHeaderProps {
  formik?: any;
}

const OrderHeader = ({ formik }: OrderHeaderProps) => {
  const dispatch = useDispatch();
  const { orderManagementData } = useSelector((state: any) => state.orderData);
  const { customerId } = useSelector((state: any) => state.OTP);

  const senderRef = useRef(null);
  const { startListening, stopListening, isListening } = useSpeechRecognize(
    formik?.setFieldValue
  );
  const { handleGetCustomer } = useFetchIput(formik);
  const { handleKeyDown } = useHandleKeyDown(startListening, stopListening);

  useEffect(() => {
    senderRef?.current?.focus();
  }, []);
   
  return (
    <div className="max-w-6xl px-6">
      {!orderManagementData && (
        <>
          <div className="flex justify-between  ">
            <h1 className=" text-lg font-semibold">اطلاعات فرستنده</h1>
            <div>
              <AutocompleteInput
                placeholder={"جستجوی کد مشتری"}
                wrapperClassName="w-[22rem] "
                inputClassname={"bg-[#F2F2F2]"}
                className={"bg-[#F2F2F2] border-none rounded-3xl"}
                items={[]}
                value={formik?.values.customerCode}
                onChange={(e) => {
                  formik.setFieldValue('customerCode', e.target.value);
                }}
                onSearchClick={handleGetCustomer}
                isButtonSearch
              />
            </div>
          </div>

          <OTP
            mobile={formik.values.sender.mobile}
            hasError={formik.errors?.sender}
            extraData={formik.values.sender}
          />
        </>
      )}

      <div className="flex-between-center  Max-md:flex-col gap-4 my-6 ">
        {!orderManagementData ? (
          <>
            <InputText
              ref={senderRef}
              label="نام و نام خانوادگی"
              name="sender.name"
              handleChange={formik?.handleChange}
              values={formik?.values.sender.name}
              important
              error={
                formik?.touched?.sender?.name && formik?.errors?.sender?.name
              }
              wrapperClassName="w-full"
              leftIcon={
                <RenderIcon
                  isListening={isListening}
                  startListening={() => startListening("sender.name")}
                  stopListening={stopListening}
                />
              }
              handleKeyDown={(e) => handleKeyDown(e, "sender.name")}
            />
            <InputText
              dir="ltr"
              label="کد ملی"
              name="sender.nationalCode"
              handleChange={formik?.handleChange}
              values={formik?.values.sender.nationalCode}
              // important
              error={
                formik?.touched?.sender?.nationalCode &&
                formik?.errors?.sender?.nationalCode
              }
              wrapperClassName="w-full"
              maxLength={10}
              leftIcon={
                <RenderIcon
                  isListening={isListening}
                  startListening={() =>
                    startListening("sender.nationalCode", true)
                  }
                  stopListening={stopListening}
                />
              }
              handleKeyDown={(e) =>
                handleKeyDown(e, "sender.nationalCode", true)
              }
            />
            <InputText
              readOnly={customerId}
              dir="ltr"
              label="موبایل"
              name="sender.mobile"
              handleChange={formik?.handleChange}
              values={formik?.values.sender.mobile}
              important
              error={
                formik?.touched?.sender?.mobile &&
                formik?.errors?.sender?.mobile
              }
              wrapperClassName="w-full"
              maxLength={13}
              leftIcon={
                customerId ? (
                  <Tick />
                ) : (
                  <RenderIcon
                    isListening={isListening}
                    startListening={() => startListening("sender.mobile", true)}
                    stopListening={stopListening}
                  />
                )
              }
              handleKeyDown={(e) => handleKeyDown(e, "sender.mobile", true)}
            />
            {customerId && (
              <button
                className="mb-4"
                type="button"
                onClick={() => {
                  dispatch(clearOTP());
                  formik.setValues({
                    ...formik.values,
                    sender: {
                      name: "",
                      nationalCode: "",
                      nationalId: "",
                      mobile: "",
                    },
                  });
                }}
              >
                <IoCloseSharp />
              </button>
            )}
          </>
        ) : (
          <>
            <InputText
              label="نام و نام خانوادگی"
              name="sender.name"
              handleChange={formik?.handleChange}
              values={orderManagementData.name}
              readOnly
              wrapperClassName="w-full"
            />
            <InputText
              label="کد ملی"
              name="sender.nationalCode"
              handleChange={formik?.handleChange}
              values={orderManagementData.nationalCode}
              readOnly
              wrapperClassName="w-full"
            />

            <InputText
              label="موبایل"
              name="sender.mobile"
              handleChange={formik?.handleChange}
              values={orderManagementData.mobile}
              readOnly
              wrapperClassName="w-full"
            />
            {/* <OtpInputWithValidation numberOfDigits={4} /> */}
          </>
        )}
      </div>
    </div>
  );
};

export default OrderHeader;
