import { useFormik } from "formik";
import AutocompleteInput from "global/Autocomplete/AutocompleteInput";
import InputSelect from "global/InputSelect/InputSelect";
import { paginationType } from "global/Interfaces/Interfaces";
import Modal from "global/Modal/Modal";
import SimpleButton from "global/SimpleButton/SimpleButton";
import { defineTerminalValidation } from "global/Validation/Validation";
import { SuccessAlert } from "global/alert/Alert";
import { useFetchHub } from "global/hooks/useFetchOptions";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { filterTerminal } from "redux/define-terminal";
import { addEditDataAPI, filterDataAPIv1, getAPI } from "services/CRUDServices";
import { addEditUrls } from "services/api.enums";

interface PropsData {
  currentData?: any;
  open: boolean;
  setOpen: (value: boolean) => void;
  setCurrentData: any;
}

const TerminalActionForm: React.FC<PropsData> = ({
  currentData,
  open,
  setOpen,
  setCurrentData,
}): JSX.Element => {
  const { pageNumbers } = useSelector(
    (state: paginationType) => state.paginate
  );
  const [Loading, setLoading] = useState(false);
  const [terminlItems, setterminalItems] = useState([]);
  const [employeeOptions, setEmployeeOptions] = useState([]);

  const { options: hubOptions } = useFetchHub();
  const dispatch = useDispatch();
  const { filter } = useSelector((state: any) => state.defineTerminal);
  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: defineTerminalValidation,
    initialValues: currentData
      ? {
          id: currentData?.id,
          terminalNo: currentData?.terminalNo,
          hubId: currentData?.selectHub,
          employeeId: currentData?.selectEmployee,
          isActive: {
            id: currentData.isActive,
            text: currentData.isActive ? "فعال" : "غیرفعال",
          },
        }
      : {
          terminalNo: "",
          hubId: {
            id: "",
            text: "",
          },
          employeeId: {
            id: "",
            text: "",
          },
          isActive: {
            id: true,
            text: "فعال",
          },
        },
    onSubmit: (values) => {
      setLoading(true);
      addEditDataAPI(
        currentData ? addEditUrls.editTerminal : addEditUrls.terminal,
        currentData ? "put" : "post",
        {
          id: values.id,
          terminalNo: values.terminalNo,
          hubId: values.hubId?.id,
          isActive: values.isActive?.id,
          employeeId: values.employeeId?.id,
        },
        true
      )
        .then((res: any) => {
          if (res.status === 200) {
            setLoading(false);
            SuccessAlert(
              currentData ? "با موفقیت ویرایش شد" : "با موفقیت ساخته شد"
            );
            dispatch(
              filterTerminal({
                ...filter,
                pageSize: 10,
                pageNumber: pageNumbers,
              }) as any
            );
          }

          setOpen(false);
        })
        .catch(() => {
          setLoading(false);
        });
    },
  });
  const {
    values,
    setFieldValue,
    handleSubmit,
    resetForm,
    errors,
    touched,
  } = formik;

  useEffect(() => {
    !open && setCurrentData();
    resetForm({});
  }, [open, resetForm, setCurrentData]);

  const getEmployee = (id) => {
    getAPI(`/resource-api/employee/findbyhubid/${id}`).then((res) => {
      setEmployeeOptions(res.data.payload);
    });
  };

  const handleChangeHub = (text, value) => {
    formik.setFieldValue(text, value);
    setEmployeeOptions([]);
    setFieldValue("employeeId", {
      id: "",
      text: "",
    });
    getEmployee(value.id);
  };

  useEffect(() => {
    console.log(currentData);
    if (currentData?.selectHub?.id) {
      getEmployee(currentData?.selectHub?.id);
    }
  }, [open]);

  const getTerminalInfo = (e: any) => {
    formik.setFieldValue("terminalNo", e.target.value);
    filterDataAPIv1("/core-api/terminals/v1/s3/terminalSuggestion", {
      terminalNo: e.target.value,
    }).then((res: any) => {
      setterminalItems(
        res.data.response.items.map((item) => ({
          id: item.id,
          label: item.text,
        }))
      );
    });
  };

  return (
    <>
      <Modal
        visible={open}
        setVisible={setOpen}
        title={currentData ? "ویرایش " : "افزودن "}
      >
        <form onSubmit={handleSubmit}>
          <div className="grid2">
            <AutocompleteInput
              label="شماره ترمینال"
              important
              items={terminlItems}
              value={formik?.values.terminalNo}
              onChange={(e) => getTerminalInfo(e)}
              onSelect={(val: any, all) => {
                setFieldValue("terminalNo", val);
              }}
              error={touched.terminalNo && errors.terminalNo}
            />
            <InputSelect
              label="  هاب"
              important
              name="hubId"
              handleChange={(value, text) => handleChangeHub(value, text)}
              values={values.hubId}
              error={touched.hubId && errors.hubId}
              options={hubOptions}
              positionFixed
            />
            <InputSelect
              label="پرسنل"
              name="employeeId"
              handleChange={setFieldValue}
              values={values.employeeId}
              error={touched.employeeId && errors.employeeId}
              options={employeeOptions}
              positionFixed
              isClearable
            />
            <InputSelect
              label="وضعیت"
              important
              name="isActive"
              handleChange={setFieldValue}
              values={values.isActive}
              error={touched.isActive && errors.isActive}
              options={[
                { id: true, text: "فعال" },
                { id: false, text: "غیر فعال" },
              ]}
              positionFixed
            />
          </div>
          <div className="btnForm">
            <SimpleButton
              handelClick={() => setOpen(false)}
              text="لغو"
              className="full-lightTomato-btn"
            />
            <SimpleButton
              loading={Loading}
              type="submit"
              text={currentData ? "ذخیره تغییرات" : "تایید"}
              className="full-tomato-btn"
            />
          </div>
        </form>
      </Modal>
    </>
  );
};

export default TerminalActionForm;
