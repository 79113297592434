import Modal from "global/Modal/Modal";
import React from "react";

function TerminalHistory({ open, setOpen,historyData }) {
  return (
    <Modal visible={open} setVisible={setOpen} title={"سابقه ترمینال"}>
      <div className="text-gray-500 mb-4 w-96">
        <span>شماره ترمینال: </span>
        <span className="text-gray-800 font-semibold">12345678</span>
      </div>

      <div className="overflow-x-auto">
        <table className="w-full text-sm text-right border-collapse">
          <thead className="border-b">
            <tr>
              <th className="py-2 px-2 text-gray-500">از تاریخ</th>
              <th className="py-2 px-2 text-gray-500">تا تاریخ</th>
              <th className="py-2 px-2 text-gray-500">هاب</th>
              <th className="py-2 px-2 text-gray-500">پرسنل</th>
            </tr>
          </thead>
          <tbody>
            <tr className="border-b">
              <td className="py-2 px-2">1401/08/08</td>
              <td className="py-2 px-2">1401/08/08</td>
              <td className="py-2 px-2">تهران</td>
              <td className="py-2 px-2">علیرضا عبدالهی</td>
            </tr>
            <tr className="border-b">
              <td className="py-2 px-2">1401/08/08</td>
              <td className="py-2 px-2">1401/08/08</td>
              <td className="py-2 px-2">تهران</td>
              <td className="py-2 px-2">سحر رضایی</td>
            </tr>
            <tr>
              <td className="py-2 px-2">1401/08/08</td>
              <td className="py-2 px-2">1401/08/08</td>
              <td className="py-2 px-2">تهران</td>
              <td className="py-2 px-2">ابراهیم ابوترابی</td>
            </tr>
          </tbody>
        </table>
      </div>
    </Modal>
  );
}

export default TerminalHistory;
