import Modal from "global/Modal/Modal";
import SimpleButton from "global/SimpleButton/SimpleButton";

function VerificationModal({ open, setOpen, mobile, sendOTP }) {

  return (
    <>
      <Modal visible={open} setVisible={setOpen} title="ارسال کد اعتبارسنجی">
        <div className="px-20">
          <p className="pt-8 pb-10  text-lg text-right">
            کد تایید به شماره {mobile} ارسال شود؟
          </p>
        </div>

        <div className="btnForm">
          <SimpleButton
            handelClick={() =>
              setOpen((prev) => ({ ...prev, verificationModal: false }))
            }
            text="لغو"
            className="text-tomato "
          />
          <SimpleButton
            handelClick={()=>sendOTP(mobile)}
            type="button"
            text="ارسال کد"
            className="full-tomato-btn"
          />
        </div>
      </Modal>
    </>
  );
}

export default VerificationModal;
