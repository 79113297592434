import React, { useEffect, useState } from "react";
import { BiX, BiXCircle } from "react-icons/bi";
import SimpleButton from "../SimpleButton/SimpleButton";
import { useDispatch } from "react-redux";
import {
  Actionpage,
  clearPaginate,
} from "redux/PaginationAction/PaginationAction";

interface propsData {
  formData: any;
  filterData: any;
  isWrapItem?: boolean;
  handleRemove?: any;
  CustomSelectType?: any;
  action?: any;
}

const Chip: React.FC<propsData> = ({
  filterData,
  formData,
  isWrapItem,
  handleRemove,
  CustomSelectType,
  action,
}: propsData) => {
  const dispatch = useDispatch();
  const [chipData, setChipData] = useState<any>([]);
  const handleRemoveChipData = (id: number) => {
    const findData = chipData.find((item: { id: number }) => item.id === id);
    const filterData = chipData.filter(
      (item: { id: number }) => item.id !== id
    );
    const searchData = filterData.map((item: string) => item.search);

    setChipData(filterData);
    if (
      findData.mainTitle === "pelak" ||
      findData.mainTitle === "vehicleNumber"
    ) {
      formData.setFieldValue("vehicleNumber0", "");
      formData.setFieldValue("vehicleNumber1", "");
      formData.setFieldValue("vehicleNumber2", "");
      formData.setFieldValue("vehicleNumber3", "");
    }
    formData.setFieldValue("fromDate", "");
    formData.setFieldValue(
      Object.keys(findData?.search).toString(),
      formData.initialValues[Object.keys(findData?.search).toString()]
    );
    //  formData.setFieldValue(Object.keys(findData?.search).toString(), ""); //empty input data

    formData.handleSubmit(Object.assign({}, ...searchData)); //run filter data
    findData.mainTitle === "selectHubList" && action?.();
  };

  //remove all filtered search
  const handleRemoveSearchFilters = () => {
    dispatch(clearPaginate(1));
    dispatch(Actionpage(1));
    setChipData([]);
    formData.resetForm({});
    // setShowSearchFilters(false);
    formData.handleSubmit();
    handleRemove && handleRemove();
    action && action();
  };
  const persianName: any = {
    hubList: "لیست هاب ها",
    hubType: "نوع هاب",
    selectMdlDriver: "نام راننده وانت",
    selectStatusList: "وضعیت ها",
    hubId: "هاب",
    employeeId: "پرسنل",
    terminalNo:"شماره ترمینال",
    selectGridDeliveryStatus: "وضعیت توزیع",
    isDriverAssigned: "تخصیص به راننده",
    selectDriverList: "نام موزع",
    selectPaymentType: "نوع پرداخت",
    selectConsignmentStatusList: "وضعیت مرسوله",
    receiver: "نام گیرنده",
    receiverMobile: "موبایل گیرنده",
    senderName: "نام فرستنده",
    senderMobile: "موبایل فرستنده",
    isSettled: "وضعیت تسویه",
    selectHubList: "لیست هاب ",
    sourceCity: "شهر مقصد",
    barcode: "شماره مرسوله",
    receiptCode: "شماره رسید",
    hasCod: "هزینه کالا دارد؟",
    hasPostPack: "هزینه پس کرایه دارد؟",
    hasAfterPaid: "هزینه پس کرایه دارد؟",
    terminalNumber: "شماره ترمینال",
    selectDepositPaymentType: "نوع پرداخت",
    selectDepositPaymentVia: "روش پرداخت",
    customerName: "نام مشتری",
    cprNum: "شماره مرسوله",
    selectPaymentBy: "پرداخت با",
    orderTrackingCode: "شماره سفارش",
    selectTrip: "شماره سفر",
    selectContradictionType: "نوع مغایرت",
    selectLabelRequestType: "نوع",
    isPickUpFromHub: "جمع آوری از هاب",
    selectEmployee: "نام کاربر",
    carTagDto: {
      vehicleNumber0: "پلاک",
      vehicleNumber1: "پلاک",
      vehicleNumber2: "پلاک",
      vehicleNumber3: "پلاک",
    },
    vehicleNumber0: "پلاک",
    vehicleNumber1: "پلاک",
    vehicleNumber2: "پلاک",
    vehicleNumber3: "پلاک",
    selectVehicleMake: "نوع وسیله نقلیه",
    code: "کد ",
    carryer: "شرکت حمل کننده",
    name: "نام ",
    search: "کد",
    hub: "نام هاب",
    hubTypeId: "نوع هاب",
    hubCategoryId: "گونه هاب",
    parentHubId: "هاب والد",
    bagNumber: "شماره کیسه",
    selectBagType: "نوع کیسه",
    sourceHub: "هاب مبدا",
    targetHub: "هاب مقصد",
    selectResourceHub: "هاب مبدا",
    selectdestinationHub: "هاب مقصد",
    destinationHub: "هاب مبدا",
    hubCode: "کد هاب",
    hubName: "نام هاب",
    route: "مسیر",
    pelak: "پلاک",
    description: "توضیحات",
    productGroup: "گروه محصول",
    timeUnit: "واحد",
    vehicleNumber: "شماره پلاک",
    phone: "شماره موبایل",
    email: "پست الکترونیکی",
    username: "نام کاربری",
    nationalCode: "کد ملی",
    postalCode: "کد پستی",
    address: "آدرس",
    telNumber: "شماره تلفن",
    selectParentCustomer: "مشتری والد",
    zipCode: "کد پستی",
    parentClient: "مشتری والد",
    personelCode: "کد پرسنلی",
    priceListDate: "تاریخ نرخ نامه",
    product: "محصول",
    consignmentType: "نوع مرسوله",
    classification: "نوع رده",
    source: "مبدا",
    destination: "مقصد",
    type: "نوع",
    priceList: "نرخ نامه",
    selectThirdPartyCategory: "گروه شخصیت",
    customerSegments: "مشتری ها",
    saleschannels: "کانال فروش",
    Delivery: "تحویل شرکت حمل کننده",
    serviceDeliveryCustomers: "گروه مشتری",
    service: "سرویس",
    fromCountryDevision: "مبدا",
    toCountryDevision: "مقصد",
    vehicleMakeSelect: "مدل",
    selectRoute: "نام مسیر",
    fuelTypeSelect: "نوع سوخت",
    consignmentCapacity: "ظرفیت مرسوله",
    volumeCapacity: "ظرفیت حجمی",
    weightCapacity: "ظرفیت وزنی",
    vendorSelect: "شرکت نقلیه",
    customercode: "کد مشتری",
    consigmentnumber: "شماره مرسوله",
    cprNumber: "شماره مرسوله",
    numberProduct: "شماره مرسوله",
    tripnumber: "شماره سفر",
    selectCarrier: "شماره سفر",
    year: "سال",
    categoryType: "رده",
    triptype: "نوع ماموریت",
    vehicleStatus: "وضعیت",
    drivingLicenceNumber: "شماره گواهینامه",
    nationalcode: "کد ملی",
    mobile: "شماره موبایل",
    isPresence: "وضعیت حضور",
    dispatchShift: "شیفت اعزام",
    customer: "مشتری",
    hubAllocatedType: "وضعیت تخصیص",
    pickupFrom: "جمع آوری از",
    pickupstatus: "وضعیت جمع آوری",
    podiHub: "هاب",
    priorotyType: "نوع الویت",
    zoneAllocated: "تخصیص منطقه",
    selectRegion: "منطقه",
    curenthub: "هاب",
    selectfirstDriver: "راننده",
    selectvehicleType: "نوع",
    selectvehicleMake: "مدل",
    selectVehicle: "آیدی پلاک",
    tripStatus: "وضعیت سفر",
    tripNumber: "شماره سفر",
    selectType: CustomSelectType || "نوع سفر",
    selectShift: "شیفت اعزام",
    resultCustomer: "مرسوله",
    selectSenderCustomer: "نام فرستنده",
    selectReceiver: "نام گیرنده",
    selectDeliveryHub: "محل توزیع",
    selectDeliveryAddress: "عنوان مرکز (توزیع)",
    selectPriority: "نوع ارسال",
    selectConsignmentContent: "محتوای مرسوله",
    selectConsignmentService: "سرویس پایه",
    selectOrderService: "سرویس جانبی",
    selectPayment: "وضعیت صورتحساب",
    selectStatus: "وضعیت مرسوله",
    trackingCode: "کد رهگیری",
    selectCustomer: "مشتری",
    deliveryHubId: "توزیع به هاب",
    serviceType: "نوع سرویس ",
    selectConsignmentType: "وضعیت",
    customerId: "شماره مشتری ",
    driver: "راننده تخصیصی",
    isAssignedToTrip: "تخصیص شده به سفر",
    isAssignedToDriver: "تخصیص شده به راننده",
    reSchedulerDateReason: "دلیل زمانبندی مجدد",
    isRegionAssigned: "تخصیص منطقه",
    region: "منطقه",
    exceptionType: "خطا",
    cnnDirection: "جهت حرکت",
    countForDelivery: "تعداد مراجعات جهت تحویل",
    countForFetch: "تعداد مراجعات جهت جمع آوری",
    serviceName: "عنوان سرویس",
    isDeliveryFromHub: "جمع آوری از هاب ",
    executeType: "وضعیت اجرا",
    isValueOfConsignmentChecked: "ارزش مرسوله",
    isPaid: "تسویه حساب",
    customerOrProspectId: "گیرنده",
    destinationHubId: "هاب مقصد",
    senderCustomerId: " فرستنده",
    nextHubId: "هاب بعدی",
    status: "وضعیت",
    selectHub: "هاب",
    selectCustome: "مشتری",
    selectCustomerCode: "کد/شناسه فرستنده",
    selectDistribution: "محل توزیع",
    serialNumber: "شماره بارکد",
    pickUpFrom: "جمع آوری از",
    allocationStatus: "وضعیت تخصیص",
    isAssignedToRegion: "تخصیص منطقه",
    selectConsignment: "مرسوله",
    vehicleMake: "نوع وسیله نقلیه",
    requestNo: "شماره درخواست",
    requestDate: "تاریخ درخواست",
    selectRequestType: "نوع",
    selectLabelRequestStatus: "وضعیت",
    licenseNo: "شماره مجوز",
    fromDate: "از تاریخ",
    toDate: "تا تاریخ",
    tripNo: "شماره سفر",
    trip: "شماره سفر",
    isDeliveryPossible: "امکان توزیع مرسوله به محل مشتری",
    isPossibleConsignmentStorage: "امکان دریافت مرسوله",
    isPossibleOrderRegistration: "امکان مدیریت سفارش",
    isPickupPossible: "امکان جمع آوری از محل مشتری",
    toStartDate: "تاریخ شروع سفر",
    toEndDate: "تاریخ پایان سفر",
    selectTripType: "نوع سفر",
    selectFirstDriver: "راننده وسیله نقلیه",
    selectDriver: "راننده",
    statusBag: "وضعیت کیسه",
    countForPickup: "تعداد مراجعات جهت جمع آوری",
    selectInvoiceStatus: "وضعیت صورتحساب",
    createdDateDto: "تاریخ",
    selectState: "استان",
    selectCity: "شهر",
    isEdited: "اصلاح",
  };
  const valueAccessor = (value: any) => {
    if (value["label"]) {
      return value.label;
    } else if (value["text"]) {
      return value.text;
    } else if (value["year"] && value["month"] && value["day"]) {
      return value.year + "/" + value.month + "/" + value.day;
    } else if (value["day"]) {
      return value.day;
    } else if (Array.isArray(value)) {
      return value.map((item) => item.text);
    } else {
      return value;
    }
  };
  useEffect(() => {
    delete filterData?.isActive;
    const convertObject = Object.entries(filterData).map(
      ([key, value]: any, index) =>
        value
          ? {
              search: { [`${key}`]: value },
              id: index,
              mainTitle: key,
              title: persianName[key],
              value: valueAccessor(value),
            }
          : ""
    );
    setChipData(convertObject.filter((item) => item));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterData]);

  return (
    <>
      <div
        className={`flex  justify-start items-center gap-3  ${
          isWrapItem ? "flex-wrap" : ""
        }`}
      >
        {chipData
          .filter(
            (item: { mainTitle: string }) =>
              item?.mainTitle !== "isActive" &&
              item.mainTitle !== "vehicleNumber0" &&
              item.mainTitle !== "vehicleNumber1" &&
              item.mainTitle !== "vehicleNumber2" &&
              item.mainTitle !== "vehicleNumber3"
          )
          .map(({ id, title, value }: any) => (
            <div
              key={id}
              className="bg-mainGray w-fit py-1 px-3 flex-between-center rounded-lg  shadow whitespace-nowrap flex-wrap  text-wrap overflow-x-auto"
            >
              <span className="text-darkGray pl-1 ">{title}:</span>
              <span className="text-dark " >{value}</span>
              <span
                className="cursor-pointer mr-2"
                onClick={() => {
                  handleRemoveChipData(id);
                }}
              >
                <BiXCircle size={18} className="text-dark" />
              </span>
            </div>
          ))}
        {chipData.length > 0 ? (
          <SimpleButton
            handelClick={handleRemoveSearchFilters}
            className="line-tomato-btn p-0 !border-none"
            text="حذف جستجوها"
            icon={<BiX size={22} />}
          />
        ) : null}
      </div>
    </>
  );
};

export default Chip;
