import { AsyncThunk, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { filterDataAPIv1 } from "services/CRUDServices";
import { filterUrls } from "services/api.enums";

export const filterTerminal: AsyncThunk<{}, {}, {}> =
  createAsyncThunk("defineterminal", async (body: any) => {
    // delete body.pageNumber
    const data = await filterDataAPIv1(
      filterUrls.terminal,
      { ...body, pageNumber: undefined, pageSize: undefined },
      body.pageNumber
    );

    return data.data;
  });

const initialState: any = {
  defineTerminal: [],
  fetchpost: false,
  errorMessage: null,
  isUpdating: false,
  filter: {},
};

const DefinTerminlaSlice = createSlice({
  initialState: initialState,
  name: "defineterminal",
  reducers: {
    clearTerminal: (state) => {
      state.defineTerminal = [];
    },
    setFilterTerminal: (state, action) => {
      state.filter = action.payload;
    },
  },
  extraReducers: {
    [filterTerminal.fulfilled as any]: (state, action) => {
      state.defineTerminal = action.payload;
      state.fetchpost = false;
    },
    [filterTerminal.pending as any]: (state) => {
      state.fetchpost = true;
    },
    [filterTerminal.rejected as any]: (state) => {
      state.fetchpost = false;
      state.errorMessage = "wrong";
    },
  },
});

export const { setFilterTerminal, clearTerminal } =
  DefinTerminlaSlice.actions;
export default DefinTerminlaSlice.reducer;
