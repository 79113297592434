import { useState } from "react";
import { toast } from "react-toastify";
import useSendOTP from "./hook/useSendOTP";
import OTPModal from "./OTPModal";
import VerificationModal from "./verificationModal";
import { OTPTypes } from "./types";
import { useSelector } from "react-redux";

interface OTPProps{
  mobile:number
  hasError:boolean
  extraData?:any
}

function OTP({ mobile, hasError,extraData }:OTPProps) {

  const [modals, setModals] = useState<OTPTypes>({
    verificationModal: false,
    OTPModal: false,
  });
  const { sendOTP } = useSendOTP({ setModals });
  const { customerId } = useSelector((state: any) => state.OTP);

  return (
    <>
      {!customerId && (
        <button
          type="button"
          onClick={() =>
            !hasError && mobile 
              ? setModals({ ...modals, verificationModal: true })
              : toast.warning("مقادیر را بدرستی وارد کنید")
          }
          className="text-left text-[#007AFF]  cursor-pointer float-left relative bottom-2"
        >
          ارسال کد اعتبار سنجی
        </button>
      )}

      {modals.verificationModal && !hasError && (
        <VerificationModal
          open={modals.verificationModal}
          setOpen={setModals}
          mobile={mobile}
          sendOTP={sendOTP}
        />
      )}

      {modals.OTPModal && (
        <OTPModal
          open={modals.OTPModal}
          setOpen={() => setModals({ ...modals, OTPModal: false })}
          mobile={mobile}
          extraData={extraData}
        />
      )}
    </>
  );
}

export default OTP;
