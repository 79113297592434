import React from "react";
import { useDispatch } from "react-redux";
import { setCustomerId, setIsCustomerHasCode } from "redux/otp";
import { getAPI } from "services/CRUDServices";

function useFetchIput(formik) {
  const dispatch = useDispatch();
  const handleGetCustomer = () => {
    getAPI(
      `/customer-api/customer-management/customers/${formik?.values?.customerCode}/by-code`
    ).then((res: any) => {
      const data = res.data.response;
      dispatch(setIsCustomerHasCode(true));
      dispatch(setCustomerId(res.data.id));
      formik.setValues({
        ...formik.values,
        customerCode: formik?.values?.customerCode,
        sender: {
          ...formik.values.sender,
          mobile: data.mobile,
          nationalCode: data.nationalCode || data.nationalId,
          name: data.name,
          code: data.code,
          customerId: data.id,
        },
      });
    }).catch(()=>{
      formik.setValues({
        ...formik.values,
        customerCode: formik?.values?.customerCode,
        sender: {
          ...formik.values.sender,
          mobile: '',
          nationalCode: '',
          name:'',
          code: '',
        },
      });
    });
  };

  return { handleGetCustomer };
}

export default useFetchIput;
