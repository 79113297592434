export const REQUIRED = "اجباری است";
export const DEFINED = "باید تعریف شود";
export const NOTTYPE = "نوع ورودی نامعتبر است";
export const INVALID = "نامعتبر است";
export const VALIDEMAIL = "ایمیل نامعتبر است";
export const VALIDMOBILE = "موبایل نامعتبر است";
export const VALIDNATIONALCODE = "کد ملی باید معتبر باشد";
export const VALIDPOSTALCODE = "کد پستی باید ده رقم باشد";
export const VALIDNATIONALID = "شناسه ملی باید معتبر باشد";
export const VALIDUSERNAME = "نام کاربری نامعتبر";
export const MINCHAR = "تعداد کاراکترها کم می باشد";
export const MAXCHAR = "تعداد کاراکترها زیاد می باشد";
export const UNMATCHPASSWORD = "رمز عبور مطابقت ندارد";
export const VALIDCOMPLEXREGEX =
  "پسورد باید شامل  حداقل 8 کاراکتر ،حروف بزرگ و کوچک  ،کاراکتر های ویژه  و عدد باشد";
export const VALIDENGPASSWORD = "رمز عبور باید شامل اعداد و حروف لاتین باشد";
export const VALIDLATINUSERNAME = "نام کاربری باید شامل حروف کوچک لاتین باشد";
export const VALIDATEPLATECODE = "پلاک  وارد شده صحیح نیست ";
export const VALIDATEREQNUMBER = '  مقدار نامعتبر';
export const MAXNUM = "حداکثر ${max}"
export const MAINIMUM = "حداقل ${min}"

