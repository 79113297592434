import OtpInputWithValidation from "components/otpInput";
import Modal from "global/Modal/Modal";
import SimpleButton from "global/SimpleButton/SimpleButton";
import { useEffect, useState } from "react";
import CountDown from "./ccount-down";
import useCountDown from "./hook/useCountDown";
import useVerifyOTP from "./hook/useVerifyOTP";
import useSendOTP from "./hook/useSendOTP";

function OTPModal({ open, setOpen,mobile,extraData  }) {
  const [otpError, setOtpError] = useState(true);
  const { timeLeftData, startTime } = useCountDown(120);
  const { sendOTP } = useSendOTP({});
  const { verifyOTP, loading } = useVerifyOTP(
    setOpen,
    mobile,
    extraData
  );
  useEffect(() => {
    open && startTime();
  }, [open]);

  function startVerifyOTP() {
    startTime();
    sendOTP(mobile);
    // verifyOTP()
  }

  return (
    <Modal visible={open} setVisible={setOpen} title="ارسال کد اعتبارسنجی">
      <div className="p-8 ">
        <p className="mb-4 text-lg">
          کد ارسال شده به شماره {mobile} را در کارد زیر وارد کنید.
        </p>
        <OtpInputWithValidation
          numberOfDigits={5}
          setOtpError={setOtpError}
          otpError={otpError}
          // code={otpCode}
        />
        <CountDown time={timeLeftData} startVerifyOTP={startVerifyOTP} />
      </div>
      <div className="btnForm">
        <SimpleButton
          handelClick={() => setOpen(false)}
          text="انصراف"
          className="text-tomato"
        />
        <SimpleButton
          handelClick={() => verifyOTP()}
          type="submit"
          text="تایید"
          className="full-tomato-btn"
          disabled={otpError}
          loading={loading}
        />
      </div>
    </Modal>
  );
}

export default OTPModal;
