
function CountDown({ time, startVerifyOTP }) {
  return (
    <div className="text-center">
      {time === "0:00" ? (
        <button onClick={startVerifyOTP}>ارسال مجدد کد</button>
      ) : (
        `${time} تا ارسال مجدد`
      )}
    </div>
  );
}

export default CountDown;
