import { calculateTax, getDay } from "tools/functions/Methods";
import { getCurrentTime, getToday } from "./portal/helper";

function MultiConsignments({ item }) {

  const renderEmpty = (item) => {
    return item || 0;
  };

  const totalPackingCost = item.consignments.reduce(
    (acc, curr) => acc + curr?.packingCost,
    0
  );
  const totalServiceCost = item.consignments.reduce((a, b) => a + b.price, 0);
  const totalSumOfCost = totalServiceCost + totalPackingCost;
  const taxPrice = calculateTax(item.invoicePrices);

  return (
    <>
      {/* <table hidden id="header-table-multi" className="border   w-full">
        <tbody>
          <tr>
            <td>تاریخ و زمان چاپ: {getToday() + "  ساعت:" + getCurrentTime()}</td>
            <td className="centerText">رسید فرستنده </td>
          </tr>
          <tr>
            <td>
              تاریخ ثبت سفارش :{getDay(item?.createdDate)} ساعت:{" "}
              {item?.createdDate?.hour}:{item?.createdDate?.minute}
            </td>
            <td className="centerText">مرسولات انبوه</td>
            <td></td>
          </tr>
        </tbody>
      </table> */}
      <table hidden id="my-table-multi" className="border  w-full">
        <tbody>
          <tr>
            <td colSpan={12} className="head">
              {" "}
              اطلاعات فرستنده{" "}
            </td>
          </tr>
          <tr>
            <td colSpan={3}>
              مبدا: {item?.senderAddressName || item?.pickupHubAddress}
            </td>
            <td colSpan={3}>شماره مشتری : {item?.customerCode}</td>
            <td colSpan={3}>تلفن:{item?.senderCustomerPhone}</td>
            <td colSpan={3}>نام : {item?.senderCustomerName}</td>
          </tr>
          <tr>
            <td colSpan={12} className="head">
              {" "}
              اطلاعات مرسولات{" "}
            </td>
          </tr>
          <tr>
            <td> هزینه خدمات (ریال)</td>
            <td> هزینه بسته بندی (ریال) </td>
            <td className="center"> ارزش اظهار شده (ریال) </td>
            <td> وجه امانی (COD) </td>
            <td> نحوه پرداخت </td>
            <td>نوع خدمت</td>
            <td> محتویات </td>
            <td>وزن (KG) </td>
            <td> مقصد </td>
            <td> گیرنده </td>
            <td>شماره مرسوله</td>
            <td> ردیف </td>
          </tr>
          {item.consignments.map((consignment, index) => (
            <tr key={consignment.id}>
              <td> {renderEmpty(consignment.price)?.toLocaleString()} </td>
              <td>
                {" "}
                {renderEmpty(consignment.packingCost)?.toLocaleString()}{" "}
              </td>
              <td className="center">
                {consignment?.declarativeValue?.toLocaleString()}{" "}
              </td>
              <td>
                {" "}
                {renderEmpty(consignment.costOfGoods)?.toLocaleString()}{" "}
              </td>
              <td>
              {
                PaymentEnum[consignment?.paymentBy] || ''
                }
              </td>
              <td> {consignment.ServiceName} </td>
              <td> {consignment?.selectContent?.text}</td>
              <td> {consignment.declarativeWeight} </td>
              <td> {consignment.toCountryDevisionName} </td>
              <td> {consignment.receiverCustomerName}</td>
              <td> {consignment.cprNumber} </td>
              <td> {index+1} </td>
            </tr>
          ))}

          <tr>
            <td>{renderEmpty(totalServiceCost).toLocaleString()}</td>
            <td> {totalPackingCost.toLocaleString()}</td>
            <td colSpan={10} className="total">
              (جمع) <td>ریال </td>
            </td>
          </tr>
          <tr>
            <td colSpan={2} >
              {
                totalSumOfCost.toLocaleString()
                // calculateOrderPrice(item.invoicePrices)?.toLocaleString()
              }
            </td>
            <td colSpan={10} >
              {" "}
              (جمع کل ) <td>ریال</td>
            </td>
          </tr>
          <tr>
            <td colSpan={2} >{taxPrice?.toLocaleString()} </td>
            <td colSpan={10} >
              {" "}
              (مالیات ارزش افزوده ) <td>ریال</td>
            </td>
          </tr>
          <tr>
            <td colSpan={2} >
              {renderEmpty(taxPrice + totalSumOfCost).toLocaleString()}
              {/* {renderEmpty(calcTotal(item.invoicePrices).toLocaleString())} */}
            </td>
            <td colSpan={10} >
              {" "}
              (مبلغ کل )<td>ریال</td>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
}

export default MultiConsignments;


const PaymentEnum={
  SENDER:"پیش کرایه نقدی",
  RECIEVER:"پس کرایه"
}