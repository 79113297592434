import { FC, useEffect, useState } from "react";
import {
  MdCheckBoxOutlineBlank,
  MdOutlineCheckBox,
  MdOutlineIndeterminateCheckBox,
} from "react-icons/md";
import { v4 as uuidv4 } from "uuid";
import Paginations from "../../global/Pagination/Pagination";
import Spinner from "../Spinner/Spinner";
import { BeatLoader } from "react-spinners";
import { useDispatch } from "react-redux";
import {
  deselectSelected,
  selectedRowAction,
  selectedTableAction,
} from "redux/Consignment/consignmentReducer";

interface NewStaticTableProps {
  data: any;
  column: any;
  THWrapper?: any;
  HoleClassName?: any;
  selectAble?: boolean;
  pagination?: number;
  selectedState?: Array<object>;
  loading?: any;
  maxHeight?: string;
  setselectedState?: (selectedState: Array<object>) => void;
  itemsPerPage?: number;
  setRowSelected?: any;
  propsType?: boolean;
  isSticky?: boolean;
  ignoreRedux?: boolean;
  emptyElement?: React.ReactNode;
}

const NewStaticTable: FC<NewStaticTableProps> = ({
  propsType,
  data,
  column,
  THWrapper,
  selectAble,
  pagination,
  loading,
  selectedState,
  HoleClassName = "",
  setselectedState,
  maxHeight,
  itemsPerPage,
  setRowSelected,
  isSticky = false,
  ignoreRedux = false,
  emptyElement,
}) => {
  const dispatch = useDispatch();

  const [newData, setNewData] = useState<any>([]);
  useEffect(() => {
    setNewData(data);
  }, [data]);
  const [tableColumns, setTableColumns] = useState<Array<any>>(column);

  const handleDataAddDeleteSelectedData = (currentItem: any) => {
    if (setselectedState && selectedState) {
      const found = selectedState.some((el: any) => el.id === currentItem.id);

      if (found) {
        const filterDatas = selectedState.filter(
          (item: any) => item.id !== currentItem.id
        );
        const filterDeselect = selectedState.filter(
          (item: any) => item.id === currentItem.id
        );
        propsType && dispatch(deselectSelected(filterDeselect));
        setselectedState(filterDatas);
        !ignoreRedux && dispatch(selectedRowAction(filterDatas));
      } else {
        const deselectSingle = [...selectedState, currentItem];

        propsType && dispatch(selectedTableAction(deselectSingle));
        setselectedState(deselectSingle);
        !ignoreRedux && dispatch(selectedRowAction(deselectSingle));
      }
    }
  };
  const handleSelectAll = () => {
    if (setselectedState && selectedState) {
      if (selectedState.length >= 0 && selectedState.length < data.length) {
        setselectedState(data);
        !ignoreRedux && dispatch(selectedRowAction(data));

        propsType && dispatch(selectedTableAction(data));
      }
      if (selectedState.length === data.length) {
        setselectedState([]);
        !ignoreRedux && dispatch(selectedRowAction([]));

        propsType && dispatch(deselectSelected(selectedState));
      }
    }
  };

  useEffect(() => {
    let newArr: Array<any> = [...column];
    if (selectAble) {
      newArr.unshift({
        id: uuidv4(),
        header: "checkbox",
        accessor: "checkbox",
      });
    }
    setTableColumns(newArr);
  }, [column, selectAble]);

  const handleClickRow = (item: any) => {
    setRowSelected?.(item);
  };

  return (
    <>
      <div
        className={`overflow-auto  rounded-lg shadow-md  mt-6 ${HoleClassName} `}
        style={{ maxHeight: maxHeight || "auto" }}
      >
        {!loading && (
          <table
            cellPadding={"15px"}
            className="border-collapse table-auto w-full bg-white table-striped rounded-lg text-center"
          >
            <thead className="bg-lightTomato h-12 rounded-lg text-dark ">
              <tr>
                {tableColumns.map((item: any, index: number) => {
                  if (item.accessor === "checkbox") {
                    return (
                      <td key={index} className="whitespace-nowrap">
                        <button onClick={handleSelectAll}>
                          {selectedState &&
                            selectedState.length === data.length &&
                            data.length !== 0 && (
                              <MdOutlineCheckBox size={16} color="blue" />
                            )}
                          {selectedState && selectedState.length === 0 && (
                            <MdCheckBoxOutlineBlank size={16} />
                          )}
                          {selectedState &&
                            selectedState.length !== 0 &&
                            selectedState.length < data.length && (
                              <MdOutlineIndeterminateCheckBox size={16} />
                            )}
                        </button>
                      </td>
                    );
                  } else {
                    return (
                      <th key={index} className={` font-normal ${THWrapper} `}>
                        {item.header}
                      </th>
                    );
                  }
                })}
              </tr>
            </thead>
            <tbody className="">
              {newData.map((rowItem: any) => (
                <tr
                  key={rowItem.id}
                  className="even:bg-lightGray h-12 text-dark"
                >
                  {tableColumns.map((item: any, index: number, row: []) => {
                    if (item.accessor === "checkbox") {
                      return (
                        <td key={item.id} className="whitespace-nowrap">
                          <button
                            onClick={() =>
                              handleDataAddDeleteSelectedData(rowItem)
                            }
                          >
                            {selectedState &&
                            selectedState.some(
                              (el: any) => el.id === rowItem.id
                            ) ? (
                              <MdOutlineCheckBox size={16} color="blue" />
                            ) : (
                              <MdCheckBoxOutlineBlank size={16} />
                            )}
                          </button>
                        </td>
                      );
                    } else {
                      return (
                        <td
                          key={item.id}
                          className={`${
                            index === row.length - 1 && isSticky
                              ? " sticky left-0 bg-white"
                              : "whitespace-nowrap"
                          }`}
                          onClick={() => handleClickRow(rowItem)}
                        >
                          {rowItem[item.accessor] === "loading" ? (
                            <div>
                              <BeatLoader color="#EF5644" size={5} />
                            </div>
                          ) : (
                            rowItem[item.accessor]
                          )}
                        </td>
                      );
                    }
                  })}
                </tr>
              ))}
            </tbody>
          </table>
        )}

        {loading ? (
          <Spinner />
        ) : (
          data.length === 0 &&
          (emptyElement || (
            <div className="h-20 centering w-full"> موردی یافت نشد </div>
          ))
        )}
      </div>
      <Paginations pageData={pagination} itemsPerPage={itemsPerPage} />
    </>
  );
};

export default NewStaticTable;
