import { ChangeEvent, FC, useEffect, useRef, useState } from "react";
import CheckboxTree from "react-checkbox-tree";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";
import {
  MdAddBox,
  MdCheckBox,
  MdCheckBoxOutlineBlank,
  MdChevronLeft,
  MdIndeterminateCheckBox,
  MdKeyboardArrowDown,
} from "react-icons/md";
import { useOnClickOutSide } from "../../tools/hooks/click-outSide-handler";

interface CustomDropDawnTreeProps {
  classWrapper: string;
  title: string;
  treeCheckedError?: string;
  loadingNode: boolean;
  nodes: Array<any>;
  valueNodeChecked: any;
  treeChecked: Array<string>;
  noCascade?: boolean;
  deleteAble?: boolean;
  isRequired?: boolean;
  classTreeWrapper?: string;
  holeClassWrapper?: string;
  setTreeChecked?: (value: Array<string>) => void;
  nodeChecked?: (value: any) => void;
  showInputValue?: boolean;
}
const CustomDropDawnTree: FC<CustomDropDawnTreeProps> = ({
  treeChecked,
  nodes,
  title,
  treeCheckedError,
  noCascade,
  classTreeWrapper = "",
  classWrapper,
  isRequired,
  valueNodeChecked,
  deleteAble = true,
  setTreeChecked,
  nodeChecked,
  showInputValue = true,
}) => {
  const WrapperRef = useRef(null);
  const [expanded, setExpanded] = useState([]);
  const [isOpen, setisOpen] = useState<boolean>(false);
  const [treeData, setTreeData] = useState<Array<any>>(nodes ? nodes : []);
  const [filtertree, setFiltertree] = useState<string>("");

  const onFilterChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFiltertree(e.target.value);
    setTreeData(filterBy(nodes, e.target.value));
  };

  const handleModalOpen = () => {
    setisOpen(!isOpen);
  };
  const handleDeleteItem = (value: string) => {
    setTreeChecked &&
      setTreeChecked(treeChecked?.filter((item: any) => item !== value));
  };
  useOnClickOutSide(WrapperRef, isOpen, setisOpen);

  useEffect(() => {
    setTreeData(nodes);
  }, [nodes]);

  return (
    <>
      <div
        ref={WrapperRef}
        className={`autocompleteWrapper text-[#CCCCCC] ${classWrapper}`}
      >
        <div className="flex justify-center pr-[10px] text-[0.875rem] font-sm text-[#686a68] dark:text-white absolute top-[-10px] right-[15px] bg-[white]">
          {title}
          {isRequired ? (
            <span className="text-[#ef5644]">&nbsp;* &nbsp;</span>
          ) : (
            <span className="text-[#ef5644]">&nbsp; &nbsp;</span>
          )}
        </div>
        <div className="relative flex flex-row-reverse justify-between items-center w-full h-full gap-5">
          <button
            type="button"
            className="w-[15%] h-[65%] flex justify-end items-center text-[#CCCCCC] border-r-[0.5px] border-[#c9c7c7fd] "
            onClick={() => handleModalOpen()}
          >
            {isOpen ? <BiChevronUp size={26} /> : <BiChevronDown size={26} />}
          </button>
          {showInputValue ? (
            <p className="text-[#CCCCCC] w-[85%]   h-full flex items-center justify-center m-0 py-0 px-[10px] ">
              {treeChecked?.map((item: any, index: number) => (
                <span
                  key={index}
                  className={`${
                    deleteAble ? "" : "pr-[10px]"
                  } flex p-[5px] gap-[10px] 	text-[#ababab] rounded-lg bg-[#FFEAE9] w-[100%] overflow-hidden text-ellipsis whitespace-nowrap`}
                >
                  {deleteAble && (
                    <button
                      type="button"
                      className="text-[#ababab]"
                      style={{ cursor: "pointer" }}
                      onClick={() => handleDeleteItem(item)}
                    >
                      <AiOutlineCloseCircle size={"15px"} />
                    </button>
                  )}
                  {valueNodeChecked?.label}
                </span>
              ))}
            </p>
          ): <p className="text-black ">{valueNodeChecked?.label}</p>}

          <div
            className={`${classTreeWrapper} h-[274px] mx-auto  absolute rounded border border-[#c9c7c7fd] w-full top-[40px] left-0 right-0  flex-col shadow-lg  gap-3 bg-white ${
              isOpen ? "flex" : "hidden"
            }`}
          >
            <div className={`pt-4    flex  overflow-y-auto justify-center`}>
              <div className="flex flex-col items-center w-full">
                <input
                  className="h-[60%] w-[85%] py-2 mb-2 pr-2 outline-0	border-2	rounded-md "
                  type={"text"}
                  placeholder="جستجو..."
                  value={filtertree}
                  onChange={onFilterChange}
                />
                <div className=" w-full min-w-[200px] px-3">
                  <CheckboxTree
                    direction="rtl"
                    nodes={treeData}
                    checked={treeChecked}
                    expanded={expanded}
                    noCascade={noCascade}
                    onCheck={(checked: Array<string>, node: any) => {
                      setTreeChecked && setTreeChecked(checked);

                      nodeChecked && nodeChecked(node);
                    }}
                    onExpand={(expanded: any) => setExpanded(expanded)}
                    icons={icons}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <p className="text-[#d05372] text-[10px]">
        {treeCheckedError && treeCheckedError}
      </p>
    </>
  );
};

export default CustomDropDawnTree;
const filterBy = (arr: Array<any>, query: string): Array<any> => {
  return query
    ? arr.reduce((acc, item) => {
        if (item?.children?.length) {
          const filtered = filterBy(item?.children, query);
          if (filtered?.length)
            return [...acc, { ...item, children: filtered }];
        }

        const { children, ...itemWithoutChildren } = item;
        return item.label?.toLowerCase().includes(query.toLowerCase())
          ? [...acc, itemWithoutChildren]
          : acc;
      }, [])
    : arr;
};

const getByID = (tree: any, value: string) => {
  let result = null;

  if (value === tree?.value) {
    return tree;
  } else {
    if (tree.children) {
      tree.children.some((node: any) => (result = getByID(node, value)));
    }
    return result;
  }
};
const icons = {
  check: <MdCheckBox className="rct-icon rct-icon-check text-[#EF5644] " />,
  uncheck: <MdCheckBoxOutlineBlank className="rct-icon rct-icon-uncheck" />,
  halfCheck: (
    <MdIndeterminateCheckBox className="rct-icon rct-icon-half-check" />
  ),
  expandClose: <MdChevronLeft className="rct-icon rct-icon-expand-close" />,
  expandOpen: <MdKeyboardArrowDown className="rct-icon rct-icon-expand-open" />,
  expandAll: <MdAddBox className="rct-icon rct-icon-expand-all" />,
  collapseAll: (
    <MdIndeterminateCheckBox className="rct-icon rct-icon-collapse-all" />
  ),
  parentClose: <></>,
  parentOpen: <></>,
  leaf: <></>,
};
