import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { setOTP } from "redux/otp";

function OtpInputWithValidation({
  numberOfDigits,
  setOtpError,
  otpError,
  // code,
}) {
  const [otp, setOtp] = useState(new Array(numberOfDigits).fill(""));
  const otpBoxReference = useRef([]);
  const dispatch=useDispatch()
  function handleChange(value, index) {
    let newArr = [...otp];
    newArr[index] = value;
    setOtp(newArr);

    if (value && index < numberOfDigits - 1) {
      otpBoxReference.current[index + 1].focus();
    }
  }

  function handleBackspaceAndEnter(e, index) {
    if (e.key === "Backspace" && !e.target.value && index > 0) {
      otpBoxReference.current[index - 1].focus();
    }
    if (e.key === "Enter" && e.target.value && index < numberOfDigits - 1) {
      otpBoxReference.current[index + 1].focus();
    }
  }

  useEffect(() => {
    if (otp.join("").length === numberOfDigits) {
      dispatch(setOTP(otp.join("")))
      setOtpError(false);
      return 
    } else {
      setOtpError(true);
    }
  }, [otp]);

  useEffect(() => {
    otpBoxReference.current[0].focus();
  }, []);

  return (
    <article>
      <div className="flex items-center justify-center gap-4 " dir="ltr">
        {otp.map((digit, index) => (
          <input
            key={index}
            value={digit}
            maxLength={1}
            onChange={(e) => handleChange(e.target.value, index)}
            onKeyUp={(e) => handleBackspaceAndEnter(e, index)}
            ref={(reference) => (otpBoxReference.current[index] = reference)}
            className={`border  h-14 w-14 p-3 text-center rounded-md block  focus:border-2 focus:outline-none appearance-none  ${
              !otpError && otp[0] !== "" ? " border-green" : ""
            }`}
          />
        ))}
      </div>

      <p className={`text-lg text-white mt-4 ${otpError ? "error-show" : ""}`}>
        {otpError}
      </p>
    </article>
  );
}

export default OtpInputWithValidation;
