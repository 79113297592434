import { Link } from "react-router-dom";
import { BiCog, BiDownload, BiEdit, BiPlus } from "react-icons/bi";
import { ImMap } from "react-icons/im";
import { IoIosPrint, IoMdCheckmarkCircle } from "react-icons/io";
import { HiRefresh } from "react-icons/hi";
import { BsArrowDownUp, BsUpcScan } from "react-icons/bs";
import {
  MdAddLocationAlt,
  MdCalendarToday,
  MdCancel,
  MdCancelPresentation,
  MdMore,
  MdOutlineDeliveryDining,
  MdOutlineEditCalendar,
} from "react-icons/md";
import { CiBag1 } from "react-icons/ci";

import {
  IoClose,
  IoHandRightSharp,
  IoLocationOutline,
  IoLocationSharp,
  IoSettingsSharp,
} from "react-icons/io5";
import { GoDesktopDownload, GoPackage } from "react-icons/go";
import CustomSwitch from "../global/Switch/Switch";
import SimpleButton from "../global/SimpleButton/SimpleButton";
import AddButton from "../global/addButton/AddButton";
import { ExportExcel } from "../tools/functions/ExportMyExcel";
import { TiGroupOutline, TiLocation } from "react-icons/ti";
import { RiFileExcel2Line, RiTruckFill } from "react-icons/ri";
import { ReactComponent as AbsentState } from "../assets/icons/changeStateAbsent.svg";
import { ReactComponent as PresentState } from "../assets/icons/changeStatePresent.svg";
import { ReactComponent as EndTrip } from "../assets/icons/endTrip.svg";
import DriverIcon from "../assets/icons/DriverIcon";
import { GrSchedulePlay } from "react-icons/gr";
import { CgCheck, CgClose } from "react-icons/cg";
import { GiConfirmed, GiOpenBook, GiPowderBag } from "react-icons/gi";
import { AiFillTags, AiOutlineDeliveredProcedure } from "react-icons/ai";
import { TbFidgetSpinner, TbTruckReturn } from "react-icons/tb";

interface ComponentItemInterface {
  JSXItem: (
    value: any,
    permission: boolean,
    selectedState?: React.Dispatch<any>
  ) => JSX.Element;
  code: string;
}
export const componentItem: Record<string, ComponentItemInterface> = {
  A1: {
    JSXItem: (value: any, permision, selectedState) => (
      <>
        <SimpleButton
          handelClick={
            value?.onclick
              ? value?.onclick
              : () => {
                  ExportExcel(value);
                  selectedState && selectedState([]);
                }
          }
          text={value?.actionText || "خروجی اکسل"}
          rightIcon={<GoDesktopDownload size={17} />}
          loading={value?.loading}
          loadingColor="red"
        />
      </>
    ),
    code: "A1",
  },

  A2: {
    JSXItem: (value: any, permission, selectedState) => (
      <>
        {value.ToggleOptions && (permission ?? true) && (
          <AddButton ToggleOptions={value.ToggleOptions} />
        )}
        {value.to && (permission ?? true) && (
          <Link to={value.to}>
            <SimpleButton
              text="افزودن"
              className="full-tomato-btn w-40"
              icon={<BiPlus size={21} />}
            />
          </Link>
        )}
      </>
    ),
    code: "A2",
  },
  A3: {
    JSXItem: (value: any) => (
      <CustomSwitch
        wrapperClassName="!mb-0"
        active={value.data}
        handleChange={value.action}
      />
    ),
    code: "A3",
  },
  A4: {
    JSXItem: (options: any, permission) => (
      <>
        {(permission ?? true) && (
          <AddButton
            text="اسکن ورود به هاب"
            ToggleOptions={options}
            className="btn gap-2 px-0"
            rightIcon={<BsUpcScan size={22} />}
          />
        )}
      </>
    ),
    code: "A4",
  },
  A5: {
    JSXItem: (options: any, permission) => (
      <>
        {(permission ?? true) && (
          <AddButton
            text="اسکن خروج از هاب"
            ToggleOptions={options}
            className="btn gap-2 px-0"
            rightIcon={<BsUpcScan size={22} />}
          />
        )}
      </>
    ),
    code: "A5",
  },
  A6: {
    JSXItem: (value: any, permission, selectedState) => (
      <>
        {value && (permission ?? true) && (
          <SimpleButton
            handelClick={() => {
              value();
              selectedState([]);
            }}
            text="چاپ برچسب"
            rightIcon={
              <div className="flex-start-center">
                <IoIosPrint size={25} />
              </div>
            }
          />
        )}
      </>
    ),
    code: "A6",
  },

  A7: {
    JSXItem: (value: any, permission, selectedState) => (
      <>
        {(permission ?? true) && (
          <SimpleButton
            text="افزودن گروهی"
            handelClick={() => {
              value.setIsOpen(!value.isOpen);
              selectedState([]);
            }}
            rightIcon={<RiFileExcel2Line size={20} />}
          />
        )}
      </>
    ),
    code: "A7",
  },
  A8: {
    JSXItem: (value: any, permission, selectedState) => (
      <>
        {value.ToggleOptions && (permission ?? true) && (
          <AddButton
            text="لغو"
            ToggleOptions={value.ToggleOptions}
            className="btn gap-2 justify-start px-0 "
            WrapperClassName="w-20"
            rightIcon={<IoClose size={20} />}
          />
        )}
        {value.onclick && (permission ?? true) && (
          <SimpleButton
            className="w-62"
            text="لغو"
            icon={<MdCancelPresentation size={20} />}
            handelClick={() => {
              value.onclick();
              selectedState([]);
            }}
          />
        )}
      </>
    ),
    code: "A8",
  },
  A9: {
    JSXItem: (action: any) => (
      <SimpleButton
        className="w-62"
        text="دریافت اطلاعات بیشتر"
        icon={
          <HiRefresh
            className={`${action.loading ? "loading-spinner" : " "}`}
          />
        }
        handelClick={action.fetch}
      />
    ),
    code: "A9",
  },
  A10: {
    JSXItem: (value: any, permission) => (
      <>
        {value.options && (permission ?? true) && (
          <AddButton
            text="تخصیص به راننده"
            ToggleOptions={value.options}
            className="btn gap-2 px-0"
            WrapperClassName="w-[10.5rem]"
            rightIcon={
              <div className="flex-start-start">
                <DriverIcon />
                <IoHandRightSharp size={12} />
              </div>
            }
          />
        )}
        {value.handleClick && (permission ?? true) && (
          <SimpleButton
            text="تخصیص به راننده"
            handelClick={value.handleClick}
            rightIcon={
              <div className="flex-start-start">
                <DriverIcon />
                <IoHandRightSharp size={12} />
              </div>
            }
          />
        )}
      </>
    ),
    code: "A10",
  },
  A11: {
    JSXItem: (handelClick, permission) => (
      <>
        {(permission ?? true) && (
          <SimpleButton
            handelClick={handelClick}
            text="تخصیص خودکار"
            rightIcon={
              <div className="flex-start-start">
                <DriverIcon />
                <IoSettingsSharp size={12} />
              </div>
            }
          />
        )}
      </>
    ),
    code: "A11",
  },
  A12: {
    JSXItem: (handelClick, permission) => (
      <>
        {(permission ?? true) && (
          <SimpleButton
            handelClick={handelClick}
            text="تغییر هاب جمع آوری"
            rightIcon={<BsArrowDownUp size={17} />}
          />
        )}
      </>
    ),
    code: "A12",
  },
  A13: {
    JSXItem: (handelClick, permission) => (
      <>
        {(permission ?? true) && (
          <SimpleButton
            handelClick={handelClick}
            text="نمایش روی نقشه"
            rightIcon={<ImMap size={17} />}
          />
        )}
      </>
    ),
    code: "A13",
  },
  A14: {
    JSXItem: (value: any, permission) => (
      <>
        {(permission ?? true) && (
          <button
            className="flex justify-center items-center gap-3"
            onClick={value.action}
          >
            <span>تخصیصی گروهی</span>
            <TiGroupOutline style={{ width: "20px", height: "20px" }} />
          </button>
        )}
      </>
    ),
    code: "A14",
  },
  A15: {
    JSXItem: (handleClick, permission) => (
      <>
        {(permission ?? true) && (
          <SimpleButton
            handelClick={handleClick}
            text="تخصیص به منطقه"
            rightIcon={<TiLocation size={24} />}
          />
        )}
      </>
    ),
    code: "A15",
  },
  A16: {
    JSXItem: (value: any) => (
      <SimpleButton
        className="w-62"
        handelClick={value}
        text="جستجوی پیشرفته"
        rightIcon={<BiCog size={17} />}
      />
    ),
    code: "A10",
  },

  A17: {
    JSXItem: (value: any, permission) => (
      <>
        {(permission ?? true) && (
          <SimpleButton
            text="افزودن تقویم"
            handelClick={value.action}
            className="btn gap-2 px-0"
            rightIcon={<MdCalendarToday size={22} />}
          />
        )}
      </>
    ),
    code: "A17",
  },
  A18: {
    JSXItem: (value: any, permission) => (
      <>
        {(permission ?? true) && (
          <SimpleButton
            text="تغییر وضعیت به غیبت"
            handelClick={value.action}
            className="btn gap-2 px-0"
            rightIcon={<AbsentState />}
          />
        )}
      </>
    ),
    code: "A18",
  },
  A19: {
    JSXItem: (value: any, permission) => (
      <>
        {(permission ?? true) && (
          <SimpleButton
            text="تغییر وضعیت به حضور"
            handelClick={value.action}
            className="btn gap-2 px-0"
            rightIcon={<PresentState />}
          />
        )}
      </>
    ),
    code: "A19",
  },
  A20: {
    JSXItem: (value: any) => (
      <SimpleButton
        text="تخصیص به شیفت"
        handelClick={value.action}
        className="btn gap-2 px-0"
        rightIcon={<MdOutlineEditCalendar size={22} />}
      />
    ),
    code: "A20",
  },

  A21: {
    JSXItem: (options: any, permission) => (
      <>
        {(permission ?? true) && (
          <AddButton
            text="ایجاد سفر"
            ToggleOptions={options}
            className="btn gap-2 justify-start px-0 "
            WrapperClassName="w-26"
            rightIcon={<GrSchedulePlay size={20} />}
          />
        )}
      </>
    ),
    code: "A21",
  },
  A22: {
    JSXItem: (value: any, permission) => (
      <>
        {(permission ?? true) && (
          <SimpleButton
            handelClick={value}
            text="تغییر به آماده بکاری "
            rightIcon={
              <div className="flex-start-center">
                <CgCheck size={15} />
                <BsArrowDownUp size={15} />
              </div>
            }
          />
        )}
      </>
    ),
    code: "A22",
  },
  A23: {
    JSXItem: (value: any, permission) => (
      <>
        {(permission ?? true) && (
          <SimpleButton
            handelClick={value}
            text="لغو آماده بکاری "
            rightIcon={
              <div className="flex-start-center">
                <CgClose size={10} />
                <BsArrowDownUp size={15} />
              </div>
            }
          />
        )}
      </>
    ),
    code: "A23",
  },
  A24: {
    JSXItem: (value: any, permission) => (
      <>
        {value && (permission ?? true) && (
          <SimpleButton
            text="افزودن"
            className="full-tomato-btn w-40"
            icon={<BiPlus size={21} />}
            handelClick={value}
          />
        )}
      </>
    ),
    code: "A24",
  },
  A25: {
    JSXItem: (options: any, permission) => (
      <>
        {(permission ?? true) && (
          <AddButton
            text="کیسه بندی"
            ToggleOptions={options}
            className="btn gap-2 px-0"
            rightIcon={<CiBag1 size={25} />}
          />
        )}
      </>
    ),
    code: "A25",
  },
  A26: {
    JSXItem: (options: any, permission) => (
      <>
        {(permission ?? true) && (
          <AddButton
            text="باز کردن کیسه"
            ToggleOptions={options}
            className="btn gap-2 px-0"
            rightIcon={<GiPowderBag size={25} />}
          />
        )}
      </>
    ),
    code: "A26",
  },
  A27: {
    JSXItem: (value: any, permission, selectedState) => (
      <>
        {value && (permission ?? true) && (
          <SimpleButton
            handelClick={() => {
              value();
              selectedState([]);
            }}
            text="تحویل شده "
            rightIcon={
              <div className="flex-start-center">
                <MdOutlineDeliveryDining size={25} />
              </div>
            }
          />
        )}
      </>
    ),
    code: "A26",
  },
  A28: {
    JSXItem: (value: any, permission, selectedState) => (
      <>
        {value && (permission ?? true) && (
          <SimpleButton
            handelClick={() => {
              value();
              selectedState([]);
            }}
            text="جمع آوری شده "
            rightIcon={
              <div className="flex-start-center">
                <GoPackage size={25} />
              </div>
            }
          />
        )}
      </>
    ),
    code: "A26",
  },
  A29: {
    JSXItem: (options: any) => (
      <>
        <AddButton
          text="دانلود"
          ToggleOptions={options}
          WrapperClassName="w-24"
          className="btn gap-2 px-0"
          rightIcon={<BiDownload size={23} />}
        />
      </>
    ),
    code: "A29",
  },

  A30: {
    JSXItem: (value: any) => (
      <>
        <AddButton
          text="لغو سفر"
          ToggleOptions={value.ToggleOptions}
          className="btn whitespace-nowrap gap-2 justify-start px-0 "
          WrapperClassName="w-20"
          rightIcon={<IoClose size={20} />}
        />
      </>
    ),
    code: "A8",
  },

  A31: {
    JSXItem: (value: any) => (
      <>
        <AddButton
          text="عملیات بیشتر"
          ToggleOptions={value.ToggleOptions}
          className="btn whitespace-nowrap gap-2 justify-start px-0 "
          WrapperClassName="w-20"
          rightIcon={<MdMore size={20} />}
        />
      </>
    ),
    code: "A8",
  },

  // GiOpenBook
  A32: {
    JSXItem: (value: any) => (
      <>
        <AddButton
          text="عملیات بیشتر"
          ToggleOptions={value.ToggleOptions}
          className="btn whitespace-nowrap gap-2 justify-start px-0 "
          WrapperClassName="w-20"
          rightIcon={<MdMore size={20} />}
        />
      </>
    ),
    code: "A8",
  },

  A33: {
    JSXItem: (options: any) => (
      <>
        <AddButton
          text="یادداشت"
          ToggleOptions={options}
          className="btn gap-2 px-0"
          rightIcon={<GiOpenBook size={25} />}
        />
      </>
    ),
    code: "A26",
  },
  A34: {
    JSXItem: (value: any) => (
      <>
        {value && (
          <SimpleButton
            handelClick={value}
            text="تخصیص برچسب"
            rightIcon={
              <div className="flex-start-center">
                <AiFillTags size={25} />
              </div>
            }
          />
        )}
      </>
    ),
    code: "A34",
  },
  A35: {
    JSXItem: (value: any) => (
      <>
        {value && (
          <SimpleButton
            handelClick={value}
            text="جمع آوری"
            rightIcon={
              <div className="flex-start-center">
                <TbFidgetSpinner size={25} />
              </div>
            }
          />
        )}
      </>
    ),
    code: "A35",
  },
  A36: {
    JSXItem: (value: any) => (
      <>
        {value && (
          <SimpleButton
            handelClick={value}
            text=" تحویل"
            rightIcon={
              <div className="flex-start-center">
                <TbFidgetSpinner size={25} />
              </div>
            }
          />
        )}
      </>
    ),
    code: "A36",
  },
  A37: {
    JSXItem: (value: any, permission) => (
      <>
        {value && (permission ?? true) && (
          <SimpleButton
            handelClick={value}
            text=" ایجاد درخواست تحویل"
            rightIcon={
              <div className="flex-start-center">
                <AiOutlineDeliveredProcedure size={21} />
              </div>
            }
          />
        )}
      </>
    ),
    code: "A37",
  },

  A38: {
    JSXItem: (value: any) => (
      <SimpleButton
        handelClick={() => value.action()}
        text="خروجی اکسل"
        rightIcon={<GoDesktopDownload size={17} />}
      />
    ),
    code: "A38",
  },
  A39: {
    JSXItem: (value: any, permission, selectedState) => (
      <>
        {value.onclick && (permission ?? true) && (
          <SimpleButton
            className="w-62"
            text="تحویل ناموفق"
            rightIcon={<MdCancelPresentation size={20} />}
            handelClick={() => {
              value.onclick();
              selectedState([]);
            }}
          />
        )}
      </>
    ),
    code: "A39",
  },
  A40: {
    JSXItem: (value: any, permission, selectedState) => (
      <>
        {value.onclick && (permission ?? true) && (
          <SimpleButton
            className="w-62"
            text="جمع آوری ناموفق"
            rightIcon={<MdCancelPresentation size={20} />}
            handelClick={() => {
              value.onclick();
              selectedState([]);
            }}
          />
        )}
      </>
    ),
    code: "A40",
  },

  A41: {
    JSXItem: (value: any, permission) => (
      <>
        {value && (permission ?? true) && (
          <SimpleButton
            handelClick={value}
            text="عدم تایید گروهی"
            rightIcon={
              <div className="flex-start-center">
                <MdCancel size={25} />
              </div>
            }
          />
        )}
      </>
    ),
    code: "A41",
  },
  A42: {
    JSXItem: (value: any, permission) => (
      <>
        {value && (permission ?? true) && (
          <SimpleButton
            handelClick={value}
            text=" تایید گروهی"
            rightIcon={
              <div className="flex-start-center">
                <IoMdCheckmarkCircle size={25} />
              </div>
            }
          />
        )}
      </>
    ),
    code: "A42",
  },
  A43: {
    JSXItem: (value: any) => (
      <>
        {value && (
          <SimpleButton
            handelClick={value}
            text="چاپ "
            rightIcon={
              <div className="flex-start-center">
                <IoIosPrint size={25} />
              </div>
            }
          />
        )}
      </>
    ),
    code: "A43",
  },
  A44: {
    JSXItem: (value: any) => (
      <>
        {value && (
          <SimpleButton
            handelClick={value}
            text="رهگیری مغایرت "
            rightIcon={
              <div className="flex-start-center">
                <MdAddLocationAlt size={25} />
              </div>
            }
          />
        )}
      </>
    ),
    code: "A44",
  },
  A45: {
    JSXItem: (handleClick: () => void) => (
      <SimpleButton
        handelClick={handleClick}
        text="درخواست اصلاح آدرس "
        rightIcon={<IoLocationOutline size={20} />}
      />
    ),
    code: "A45",
  },
  A46: {
    JSXItem: (handleClick: () => void) => (
      <SimpleButton
        handelClick={handleClick}
        text=" درخواست اصلاح آدرس دشوار"
        rightIcon={<IoLocationSharp size={20} />}
      />
    ),
    code: "A46",
  },
  A47: {
    JSXItem: (handleClick: () => void) => (
      <SimpleButton
        handelClick={handleClick}
        text="باز کردن سفر"
        rightIcon={<RiTruckFill size={20} />}
      />
    ),
    code: "A47",
  },
  A48: {
    JSXItem: (handleClick: () => void) => (
      <SimpleButton
        handelClick={handleClick}
        text=" بستن سفر"
        rightIcon={<EndTrip className="w-[30px]" />}
      />
    ),
    code: "A48",
  },
  A49: {
    JSXItem: (handleClick: () => void) => (
      <SimpleButton
        handelClick={handleClick}
        text="عودت مرسولات"
        rightIcon={<TbTruckReturn size={25} />}
      />
    ),
    code: "A49",
  },
  A50: {
    JSXItem: (value: any, permission, selectedState) => (
      <>
        {value.onclick && (permission ?? true) && (
          <SimpleButton
            className="w-62"
            text="تحویل شده (با مشخصات)"
            rightIcon={<MdCancelPresentation size={20} />}
            handelClick={() => {
              value.onclick(true);
              selectedState([]);
            }}
          />
        )}
      </>
    ),
    code: "A50",
  },
  A51: {
    JSXItem: (value: any, permission, selectedState) => (
      <>
        {value.onclick && (permission ?? true) && (
          <SimpleButton
            className="w-62"
            text="تحویل ناموفق (با مشخصات)"
            rightIcon={<MdCancelPresentation size={20} />}
            handelClick={() => {
              value.onclick(true);
              selectedState([]);
            }}
          />
        )}
      </>
    ),
    code: "A51",
  },
  // A52: {
  //   JSXItem: (value: any, permission, selectedState) => (
  //     <>
  //       <SimpleButton
  //         className="w-62"
  //         text="رسید تحویل مرسولات پست"
  //         rightIcon={<LiaReceiptSolid size={20} />}
  //         handelClick={() => {
  //           value.onclick(true);
  //           selectedState([]);
  //         }}
  //       />
  //     </>
  //   ),
  //   code: "A52",
  // },
  A52: {
    JSXItem: (options: any, permission) => (
      <>
        {(permission ?? true) && (
          <AddButton
            text="رسید تحویل مرسولات پست"
            ToggleOptions={options}
            className="btn gap-2 px-0"
            rightIcon={<BsUpcScan size={22} />}
            WrapperClassName="w-auto"
          />
        )}
      </>
    ),
    code: "A52",
  },
  A53: {
    JSXItem: (value: any, permision, selectedState) => (
      <>
        <SimpleButton
          handelClick={() => {
            value.onclick();
          }}
          text="اصلاح آدرس"
          rightIcon={<BiEdit size={17} />}
        />
      </>
    ),
    code: "A53",
  },
  A54: {
    JSXItem: (value: any, permission) => (
      <>
        {value && (permission ?? true) && (
          <SimpleButton
            text="تخصیص برچسب هاب"
            rightIcon={
              <div className="flex-start-center">
                <AiFillTags size={25} />
              </div>
            }
            handelClick={value}
          />
        )}
      </>
    ),
    code: "A54",
  },
  A55: {
    JSXItem: (value: any, permission) => (
      <>
        {value && (permission ?? true) && (
          <SimpleButton
            text="تخصیص برچسب مشتری"
            rightIcon={
              <div className="flex-start-center">
                <AiFillTags size={25} />
              </div>
            }
            handelClick={value}
          />
        )}
      </>
    ),
    code: "A55",
  },
  A56: {
    JSXItem: (value: any, permission) => (
      <>
        {value && (permission ?? true) && (
          <SimpleButton
            text="تایید مالی"
            rightIcon={
              <div className="flex-start-center">
                <GiConfirmed size={25} />
              </div>
            }
            handelClick={value}
          />
        )}
      </>
    ),
    code: "A56",
  },

  A57: {
    JSXItem: (value: any, permision, selectedState) => (
      <>
        {(permision ?? true) && (
          <SimpleButton
            handelClick={() => {
              value.onclick();
            }}
            text="ویرایش مرسوله"
            rightIcon={<BiEdit size={17} />}
          />
        )}
      </>
    ),
    code: "A57",
  },

  A58: {
    JSXItem: (value: any, permision, selectedState) => (
      <>
        {
          <SimpleButton
            handelClick={() => {
              value.onclick();
            }}
            text="رسید بارکد پستی"
            rightIcon={<BsUpcScan size={22} />}
          />
        }
      </>
    ),
    code: "A58",
  },
  A59: {
    JSXItem: (handelClick, permission) =>
      (permission ?? true) && (
        <SimpleButton
          handelClick={handelClick}
          text="تخصیص گروهی به راننده"
          rightIcon={
            <div className="flex-start-start">
              <DriverIcon />
              <IoSettingsSharp size={12} />
            </div>
          }
        />
      ),
    code: "A59",
  },
  A60: {
    JSXItem: (handelClick, permission) => (
      <>
        {
          <SimpleButton
            handelClick={handelClick}
            text="فاکتور ارسال پیک"
            rightIcon={
              <div className="flex-start-start">
                <IoIosPrint size={25} />
              </div>
            }
          />
        }
      </>
    ),
    code: "A60",
  },
  A61: {
    JSXItem: (value: any, permission) => (
      <>
        {value && (permission ?? true) && (
          <SimpleButton
            text="ویرایش اعتبار"
            className="bg-[#333333]  w-40 text-white"
            disabled={value.isDisable}
            handelClick={value.onClick}
          />
        )}
      </>
    ),
    code: "A61",
  },
  A62: {
    JSXItem: (value: any, permission) => (
      <>
        {value && (permission ?? true) && (
          <SimpleButton
            text="تخصیص اعتبار"
            className="bg-[#333333]  w-40 text-white"
            disabled={value.isDisable}
            handelClick={value.onClick}
          />
        )}
      </>
    ),
    code: "A62",
  },
};
