import Excel from "exceljs";
import { saveAs } from "file-saver";
const workSheetName = "Worksheet-1";

export const ExportExcel = async (values) => {

  const filterColumn = values.columns.filter((item) => !item.accessor.includes("operation"));
  const workbook = new Excel.Workbook();
  try {
    // creating one worksheet in workbook
    const worksheet = workbook.addWorksheet();

    ///////////////////////////////////////////////////////////////////////////////this section is for sheet2//////////////////////////////////////////////////////////////////////////

    if (values.newSheetColumns) {
      const filterNewShhetColumn = values.newSheetColumns.filter(
        (item) => !item.accessor.includes("operation")
      );
      const worksheetWriter = workbook.addWorksheet("connection", {
        pageSetup: { fitToPage: true, fitToHeight: 5, fitToWidth: 7 },
      });
      worksheetWriter.columns = filterNewShhetColumn;

      worksheetWriter.views = [{ rightToLeft: true }];

      // updated the font for first row.
      worksheetWriter.getRow(1).font = { bold: true };

      // loop through all of the filterColumn and set the alignment with width.
      worksheetWriter.columns.forEach((column) => {
        column.width = column.header.length + 25;

        column.alignment = { horizontal: "center", vertical: "middle", readingOrder: "rtl" };
      });

      values.newSheetData.forEach((singleData: any) => {
        let row = worksheetWriter.addRow(singleData);

        row.fill = {
          type: "pattern",
          pattern: "darkGrid",
          fgColor: { argb: "EBEBEB" },
          bgColor: { argb: "EBEBEB" },
        };
      });

      // loop through currentCell to apply border only for the non-empty cell of excel
  
      worksheetWriter.eachRow({ includeEmpty: false }, (row:any, rowNumber) => {
        // store each cell to currentCell
        const currentCell = row._cells ;
  
        row.eachCell((cell: { font: { name: string; family: number; bold: boolean; size: number; color: { argb: string; }; }; }, colNumber: any) => {
          cell.font = {
            name: "Arial",
            family: 2,
            bold: true,
            size: 10,
            color: { argb: "525252" },
          };
        });

        // loop through currentCell to apply border only for the non-empty cell of excel
        currentCell.forEach((singleCell:any) => {
          // store the cell address i.e. A1, A2, A3, B1, B2, B3, ...
          const cellAddress = singleCell._address;

          // apply border
          worksheetWriter.getCell(cellAddress).border = {
            top: { style: "double", color: { argb: "F2E7E7" } },
            left: { style: "double", color: { argb: "F2E7E7" } },
            bottom: { style: "double", color: { argb: "F2E7E7" } },
            right: { style: "double", color: { argb: "F2E7E7" } },
          };
        });
      });
    }

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // add worksheet columns
    // each columns contains header and its mapping key from data
    worksheet.columns = filterColumn;

    worksheet.views = [{ rightToLeft: true }];

    // updated the font for first row.
    worksheet.getRow(1).font = { bold: true };

    // loop through all of the filterColumn and set the alignment with width.
    worksheet.columns.forEach((column) => {
      column.width = column.header.length + 25;

      column.alignment = { horizontal: "center", vertical: "middle", readingOrder: "rtl" };
    });

    // loop through data and add each one to worksheet
    values.data.forEach((singleData:any) => {
      let row = worksheet.addRow(singleData);

      row.fill = {
        type: "pattern",
        pattern: "darkGrid",
        fgColor: { argb: "EBEBEB" },
        bgColor: { argb: "EBEBEB" },
      };
    });

    // loop through all of the rows and set the outline style.
    worksheet.eachRow({ includeEmpty: false }, (row:any, rowNumber) => {
      // store each cell to currentCell
      const currentCell = row._cells;

      row.eachCell((cell, colNumber) => {
        cell.font = {
          name: "Arial",
          family: 2,
          bold: true,
          size: 10,
          color: { argb: "525252" },
        };
      });

      // loop through currentCell to apply border only for the non-empty cell of excel
      currentCell.forEach((singleCell:any) => {
        // store the cell address i.e. A1, A2, A3, B1, B2, B3, ...
        const cellAddress = singleCell._address;

        // apply border
        worksheet.getCell(cellAddress).border = {
          top: { style: "double", color: { argb: "F2E7E7" } },
          left: { style: "double", color: { argb: "F2E7E7" } },
          bottom: { style: "double", color: { argb: "F2E7E7" } },
          right: { style: "double", color: { argb: "F2E7E7" } },
        };
      });
    });

    // write the content using writeBuffer
    const buf = await workbook.xlsx.writeBuffer();

    // download the processed file
    saveAs(new Blob([buf]), `${values?.title ? values?.title : workSheetName}.xlsx`);
  } catch (error) {
  } finally {
    // removing worksheet's instance to create new one
    workbook.removeWorksheet(workSheetName);
  }
};
