
export const TerminalColumns = [
  {
    accessor: "selectHub",
    Header: "نام هاب",
    key: "selectHub",
    header: "نام هاب",

  },
  {
    accessor: "terminalNo",
    Header: "شماره ترمینال",
    key: "terminalNo",
    header: "وضعیت",

  },
  {
    accessor: "isActive",
    Header: "وضعیت",
    key: "isActive",
    header: "وضعیت",

  },
  {
    accessor: "selectEmployee",
    Header: "پرسنل",
    key: "selectEmployee",
    header: "پرسنل",

  },
  {
    Header: "عملیات",
    accessor: "operation",
    key: "operation",
    header: "",

 
  },
];
