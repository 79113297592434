import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { FiSearch } from "react-icons/fi";
import { paginationType } from "global/Interfaces/Interfaces";
import {
  clearTerminal,
  filterTerminal,
  setFilterTerminal,
} from "redux/define-terminal";
import SimpleButton from "global/SimpleButton/SimpleButton";
import Chip from "global/Chip/Chip";
import CustomDropDawnTree from "components/customDropDawntree/CustomDropDawnTree";
import InputSelect from "global/InputSelect/InputSelect";
import { getAPI } from "services/CRUDServices";
import InputText from "global/InputText/InputText";
import { findNode } from "tools/functions/Methods";

interface PropsData {
  isActive: Boolean | string;
}

const SearchForm: React.FC<PropsData> = ({ isActive }): JSX.Element => {
  const dispatch = useDispatch();
  const [treeChecked, setTreeChecked] = useState<Array<string>>([]);
 const [employeeOptions, setEmployeeOptions] = useState([]);
  const [selectedHub, setSelectedHub] = useState<any>({});
  const currentHubInfo = useSelector((state: any) => state.currentHubInfo);
  const userInfo = useSelector((state: any) => state.userInfo);
  const { pageNumbers } = useSelector(
    (state: paginationType) => state.paginate
  );
  const [filterData, setFilterData] = useState({});
  const formik = useFormik({
    // enableReinitialize: true,
    initialValues: {
      hubId: currentHubInfo?.CurrentHub,
      employeeId: null,
      terminalNo: null,

    },
    onSubmit: (values) => {
      setFilterData(values);
    },
  });
  const { values, setFieldValue, handleSubmit } = formik;

  useEffect(() => {

    const final={
      hubId: values.hubId?.id,
      employeeId: values.employeeId?.id,
      terminalNo:values.terminalNo,
      isActive:isActive
    }
    dispatch(setFilterTerminal(final));
    dispatch(
      filterTerminal({
        ...final,
        pageSize: 10,
        pageNumber: pageNumbers,
      }) as any
    );
    return () => {
      dispatch(clearTerminal());
    };
  }, [isActive, filterData, pageNumbers, dispatch]);
  useEffect(() => {
    if (currentHubInfo?.CurrentHub?.value) {
      setTreeChecked([currentHubInfo?.CurrentHub?.value]);
      setSelectedHub(currentHubInfo?.CurrentHub);
      // setFieldValue("hubId",currentHubInfo?.CurrentHub?.id)
    }
  }, [currentHubInfo?.CurrentHub]);


  useEffect(()=>{
      console.log(values)
      getAPI(`/resource-api/employee/findbyhubid/${values.hubId?.id}`).then((res) => {
          setEmployeeOptions(res.data.payload);
        });
  },[values.hubId])


  return (
    <>
      <form className="searchForm" onSubmit={handleSubmit}>
        <div className="z-[500]  filterInput  mb-5">
          <CustomDropDawnTree
            deleteAble={false}
            noCascade
            valueNodeChecked={selectedHub}
            classWrapper="autocompleteWrapper h-[42px] filterInput"
            nodes={userInfo?.hublist ? userInfo?.hublist : []}
            loadingNode={false}
            title="هاب "
            // treeCheckedError={treeCheckedError}
            holeClassWrapper="h-[110px]"
            treeChecked={treeChecked}
            setTreeChecked={setTreeChecked}
            nodeChecked={(value) => {
              console.log(findNode({ children: userInfo?.hublist }, value.value))
              setTreeChecked([value.value]);
              setSelectedHub(value);
              setFieldValue("hubId",findNode({ children: userInfo?.hublist }, value.value))
            }}
          />
        </div>

        <InputText
          label="شماره ترمینال"
          name="terminalNo"
          handleChange={formik.handleChange}
          values={values.terminalNo}
        />
        <InputSelect
          label="پرسنل"
          name="employeeId"
          handleChange={setFieldValue}
          values={values.employeeId}
          options={employeeOptions}
        />

        <SimpleButton
          type={"submit"}
          className="full-gray-btn"
          icon={<FiSearch size={25} className="text-darkGray" />}
          text="جستجو"
        />
      </form>
      {filterData && <Chip filterData={filterData} formData={formik} />}
    </>
  );
};

export default SearchForm;
