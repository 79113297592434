const DriverIcon = ({className}: any) => (
  <svg
    id="noun-driver-395485"
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="19"
    viewBox="0 0 19.445 21.61"
    className={`text-darkGray ${className}`}
  >
    <path
      id="Union_6"
      data-name="Union 6"
      d="M0,21.61V15.134a2.341,2.341,0,0,1,1.383-1.342l4.235-1.976a5.4,5.4,0,0,0,4.108,2.23,5.388,5.388,0,0,0,4.108-2.23l4.235,1.976a2.322,2.322,0,0,1,1.377,1.337V21.61Zm7.553-5.4,1.083,1.06h.012v3.25H10.8v-3.25h.012l1.083-1.06-1.083-1.118H8.637Zm6.418-1.849,2.9,1.435.478-.968-2.9-1.435ZM2.1,14.829l.478.968,2.9-1.435L5,13.394ZM5.4,5.835a15.475,15.475,0,0,0,4.021.611h.6a15.6,15.6,0,0,0,4.01-.611c-.207,2.414-2.057,6.044-4.315,6.044S5.612,8.249,5.4,5.835Zm4.217-.472a14.515,14.515,0,0,1-5.3-1.037v-.9l-.755-.276a.553.553,0,0,1,0-1.026L9.016.125a2.067,2.067,0,0,1,1.417,0l5.45,1.993h0a.556.556,0,0,1-.006,1.031l-.755.277v.9a14.559,14.559,0,0,1-5.3,1.037Z"
      fill="currentColor"
    />
  </svg>
);

export default DriverIcon;
