import Breadcrumb from "components/Breadcrumb/Breadcrumb";
import SwitchOptionTable from "components/OptionsTable/SwitchOptionTable";
import StaticTable from "components/staticTable/StaticTable";
import React, { useState } from "react";
import { AiOutlineEdit } from "react-icons/ai";
import { useSelector } from "react-redux";
import { TerminalColumns } from "./view/Column";
import SearchForm from "./view/SearchForm";
import TerminalHistory from "./view/terminal-history";
import TerminalActionForm from "./view/TerminalActionForm";
import { hasPermission } from "utils/HasPermission";
const DefineTerminal: React.FC = (): JSX.Element => {
  const [isActive, setIsACtive] = useState(true);

  const { defineTerminal, fetchpost } = useSelector(
    (state: any) => state.defineTerminal
  );

  const [currentData, setCurrentData] = useState();
  const [actionModal, setActionModal] = useState(false);
  const [historyModal, setHistoryModal] = useState(false);
  const [historyData, setHistoryData] = useState();

  const data =
    defineTerminal?.response?.items?.length !== 0
      ? defineTerminal?.response?.items?.map((item: any) => {
          return {
            ...item,
            selectHub: item.selectHub?.text || "",
            isActive: isActive ? "فعال" : "غیر فعال",
            selectEmployee: item.selectEmployee?.text,
            operation: (
              <div className="flex w-full gap-3 justify-center">
                {/* <button
                  className=" border-none	 text-[14px]  w-[20px] h-[20px] "
                  onClick={() => {
                    setHistoryData(item);
                    setHistoryModal(!historyModal);
                  }}
                >
                  <MdHistory className="w-full h-full" />
                </button> */}
                {hasPermission("editTerminal") && (
                  <button
                    className=" border-none	 text-[14px]  w-[20px] h-[20px] "
                    onClick={() => {
                      setCurrentData(item);
                      setActionModal(!actionModal);
                    }}
                  >
                    <AiOutlineEdit className="w-full h-full" />
                  </button>
                )}

                {/* <DeleteOperation
                  itemId={item.id}
                  title={"حذف ترمینال"}
                  handleDeleteActionNewData={refreshTable}
                  route={deleteUrls.dock}
                /> */}
              </div>
            ),
          };
        })
      : [];

  return (
    <div>
      <Breadcrumb beforePage="مدیریت اطلاعات پایه" curentPage="تعریف ترمینال" />
      <SearchForm isActive={isActive} />
      <SwitchOptionTable
        accessPage={[
          {
            code: "A24",
            value: () => setActionModal((prev) => !prev),
            permission:hasPermission("createTerminal")
          },
          {
            code: "A1",
            value: { data: data, columns: TerminalColumns, title: "terminal" },
          },
          {
            code: "A3",
            value: { data: isActive, action: () => setIsACtive(!isActive) },
          },
        ]}
      />
      <StaticTable
        data={data ? data : []}
        column={TerminalColumns}
        pagination={defineTerminal?.response?.totalElements}
        selectable={false}
        loading={fetchpost}
      />
      <TerminalActionForm
        open={actionModal}
        setOpen={setActionModal}
        currentData={currentData}
        setCurrentData={setCurrentData}
      />
      <TerminalHistory
        open={historyModal}
        setOpen={setHistoryModal}
        historyData={historyData}
      />
    </div>
  );
};

export default DefineTerminal;
