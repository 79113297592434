import InputNumber from "global/InputNumber/InputNumber";
import InputSelect from "global/InputSelect/InputSelect";
import InputText from "global/InputText/InputText";
import Modal from "global/Modal/Modal";
import Radio from "global/Radio/Radio";
import { useFetchOptions } from "global/hooks/useFetchOptions";
import QrScanner from "global/scanner";
import { useState } from "react";
import { BsUpcScan } from "react-icons/bs";

import { getUrls } from "services/api.enums";
import { useSpeechRecognize } from "../hooks/useSpeech-recognize";

const ConsignmentInfo = ({ formik, submitAndNew }) => {
  const [openScan, setOpenScan] = useState(false);
  const { values, setFieldValue, handleChange, setValues, errors, touched } =
      formik;


  const { dataOptions: packageLists } = useFetchOptions(
      getUrls.typesOfPackageList
  );
  const { dataOptions: contentType } = useFetchOptions(
      getUrls.getContentTypes2
  );

  const RadioWrapper = ({ label, children }) => {
    return (
        <div className="flex gap-x-10 flex-col gap-y-4 relative ">
          <div className=" absolute top-[-12px] right-4 z-50 bg-white">
            {label}
          </div>
          <div className="flex gap-x-20 bg-white h-[2.5rem]  rounded-lg autocompleteWrapper justify-center">
            {children}
          </div>
        </div>
    );
  };

  function convertRespons(option) {
    return option.map((pack) => ({ ...pack, id: pack.code, text: pack.name }));
  }

  function handleCHangePack(value: any) {
    const findPackInfo = packageLists.options.find(
        (item) => item.code === value.id
    );

    setValues({
      ...values,
      width: findPackInfo.width,
      height: findPackInfo.height,
      length: findPackInfo.length,
      packageId: value,
    });
  }

  function onScan(decodedText: string) {
    setOpenScan(false);
    setFieldValue("cprNumber", decodedText);
    submitAndNew();
  }

  return (
      <>
        <h1 className=" text-lg font-semibold">اطلاعات مرسوله</h1>
        <div className="grid grid-cols-3 Max-md:grid-cols-1  max-w-6xl Max-md:flex-col gap-x-4 gap-y-4 my-8">
          <RadioWrapper label={"نوع مرسوله"}>
            <Radio
                title="بسته"
                name={`consignmentType`}
                values={values.consignmentType.id}
                checked={1}
                handleChange={() => {
                  setFieldValue("consignmentType", { id: 1, text: "بسته" });
                }}
            />
            <Radio
                title="پاکت"
                name={`consignmentType`}
                values={values.consignmentType.id}
                checked={0}
                handleChange={() => {
                  setFieldValue("consignmentType", { id: 0, text: "پاکت" });
                }}
            />
          </RadioWrapper>

          <InputSelect
              label="نوع محتوا"
              important
              name="contentType"
              handleChange={setFieldValue}
              values={values.contentType}
              error={touched.contentType && errors.contentType}
              options={contentType.options}
              wrapperClassName="w-full"
              positionFixed
          />
          <InputText
              classNames="bg-white"
              wrapperClassName="w-full"
              important
              leftIcon={
                <div className="flex gap-3 items-center">
                  <button type="button" onClick={() => setOpenScan(!openScan)}>
                    <BsUpcScan size={30} className="text-[#EAB308] " />
                  </button>
                </div>
              }
              placeholder="شماره مرسوله  را وارد کنید یا اسکن کنید"
              handleChange={handleChange}
              name="cprNumber"
              values={values.cprNumber}
              error={touched.cprNumber && errors.cprNumber}
              maxLength={13}
          />
          <InputText
              classNames="bg-white"
              label="وزن (کیلوگرم)"
              name="weight"
              handleChange={handleChange}
              values={values.weight}
              important
              error={touched.weight && errors.weight}
              wrapperClassName="w-full"
              maxLength={6}
          />
          <InputSelect
              label="سایز کارتن"
              important
              name="packageId"
              handleChange={(_, value) => handleCHangePack(value)}
              values={values.packageId}
              error={touched.packageId && errors.packageId}
              options={convertRespons(packageLists?.options) || []}
              wrapperClassName="w-full"
              positionFixed
          />
          <InputText
              classNames="bg-white"
              label="طول (سانتی متر)"
              name="length"
              handleChange={handleChange}
              values={values.length}
              important
              error={touched.length && errors.length}
              wrapperClassName="w-full"
              maxLength={2}
          />
          <InputText
              classNames="bg-white"
              label="عرض (سانتی متر)"
              name="width"
              handleChange={handleChange}
              values={values.width}
              important
              error={touched.width && errors.width}
              wrapperClassName="w-full"
              maxLength={2}
          />
          <InputText
              classNames="bg-white"
              label="ارتفاع (سانتی متر)"
              name="height"
              handleChange={handleChange}
              values={values.height}
              important
              error={touched.height && errors.height}
              wrapperClassName="w-full"
              maxLength={2}
          />

          <RadioWrapper label={"پس کرایه"}>
            <Radio
                title="دارد"
                name={`postBack`}
                values={values.postBack}
                checked={true}
                handleChange={setFieldValue}
                isDisabled={values.thirdPartyDelivery}
            />
            <Radio
                title="ندارد"
                name={`postBack`}
                values={values.postBack}
                checked={false}
                handleChange={setFieldValue}
                isDisabled={values.thirdPartyDelivery}
            />
          </RadioWrapper>
          <RadioWrapper label={"بسته بندی"}>
            <Radio
                title="دارد"
                name={`needToPack`}
                values={values.needToPack}
                checked={true}
                handleChange={setFieldValue}
                isDisabled
            />
            <Radio
                title="ندارد"
                name={`needToPack`}
                values={values.needToPack}
                checked={false}
                handleChange={setFieldValue}
                isDisabled
            />
          </RadioWrapper>
          <RadioWrapper label={"تعهد جبران خسارت"}>
            <Radio
                title="عمومی"
                name={`insurance`}
                values={values.insurance?.id}
                checked={0}
                handleChange={() => {
                  setFieldValue("insurance", { id: 0, text: "" });
                  setFieldValue("value", "");
                }}
            />
            <Radio
                title="ارزش اظهاری"
                name={`insurance`}
                values={values.insurance?.id}
                checked={1}
                handleChange={() => {
                  setFieldValue("insurance", { id: 1, text: "" });
                }}
            />
          </RadioWrapper>
          {values.insurance.id === 1 ? (
              <InputNumber
                  // classNames="bg-white"
                  label="ارزش (ریال)"
                  name="value"
                  handleChange={setFieldValue}
                  values={values.value}
                  important
                  error={
                      touched.value &&
                      errors.value &&
                      " حداقل مبلغ 1 میلیون و حداکثر 20 میلیون تومان"
                  }
                  wrapperClassName="w-full"
              />
          ) : null}
          <RadioWrapper label={"COD"}>
            <Radio
                title="دارد"
                name={`cod`}
                values={values.cod}
                checked={true}
                handleChange={setFieldValue}
                isDisabled={values.thirdPartyDelivery}
            />
            <Radio
                title="ندارد"
                name={`cod`}
                values={values.cod}
                checked={false}
                handleChange={setFieldValue}
                isDisabled={values.thirdPartyDelivery}
            />
          </RadioWrapper>
          {values.cod ? (
              <InputNumber
                  className="mt-4"
                  label="مبلغ (ریال)"
                  name="costOfGoods"
                  handleChange={setFieldValue}
                  values={values.costOfGoods}
                  important
                  error={touched.costOfGoods && errors.costOfGoods}
                  wrapperClassName="w-full"
              />
          ) : null}
          <InputNumber
              // classNames="bg-white"
              label=" خدمات جانبی (ریال)"
              name="extraCosts"
              handleChange={setFieldValue}
              values={values.extraCosts}
              // important

              error={touched.extraCosts && errors.extraCosts}
              wrapperClassName="w-full mt-4"
          />
        </div>

        <Modal visible={openScan} setVisible={setOpenScan} title="اسکن">
          {openScan && <QrScanner onSuccess={onScan} />}
        </Modal>
      </>
  );
};

export default ConsignmentInfo;
