import HubList from "../HubData/HubData";
import RolesList from "../RolsData/RolesData";
import PageNumber from "../PaginationAction/PaginationAction";
import PersonnelList from "../PersonData/PersonsData";
import ProductDefineList from "../ProductDefineData/ProductDefineData";
import CustomerList from "../CustomerManagement/CustomerManagementData";
import AddressCorrectionList from "../AddressCorrection/AddressCorrectionData";
import PickupList from "../PickupData/PickupData";
import OrderManagementList from "../OrderManagementData/OrderManagementData";
import DeliveryList from "../DeliveryData/DeliveryData";
import VehiclePlanList from "../VehiclePlan/VehiclePlanData";
import ThirdPartyList from "../ThirdParty/ThirdPartyData";
import ADMVehicleList from "../ADMVehicle/ADMVehicleData";
import ServiceTimeList from "../ServiceTimeData/ServiceTimeData";
import PriceList from "../PriceData/PriceData";
import OrderList from "../OrderData/OrderData";
import ServiceProvision from "../ServiceProvision/ServiceProvision";
import ServiceData from "../ServiceDefine/ServiceDefineReducer";
// import EditHubTable from "../HubData/EditData";
import selectRowTable from "../selectRowTable/selectRowTable";
import VendorList from "../Transportation/vendor/VendorData";
import VehicleModelLists from "../Transportation/vehicleModel/VehicleModel";
import RouteLists from "../Transportation/route/RouteData";
import HubTypeTable from "../HubData/TypeHub";
import userInfoReducer from "../userInfo/userInfoReducer";
import VehicleLists from "../Transportation/VehicleData/VehicleData";
import BagsLists from "../Transportation/bags/Bags";
import GateLists from "../Transportation/gate/GateData";
import DockLists from "../Transportation/dock/DockData";
import ExceptionLists from "../Transportation/exception/ExceptionData";
import SalesChannelList from "../SaleChannel/SalesChannelReducer";
import customGeoReducer from "../customGeo/customGeoReducer";
import ProductGroupsData from "../ProductGroup/ProductGroup";
import CRMCustomerGroupReducer from "../CRMCustomerGroup/CRMCustomerGroupReducer";
import timeShiftData from "../TRMManagement/TRMManagementData";
import consignmentReducer from "../Consignment/consignmentReducer";
import DriverListsData from "../TRMManagement/DriverManagement/DriverManagementData";
import BagReducer from "../MDLbags/MDLbags";
import VeheicleMove from "../VeheicleMove/VeheicleMove";
import lableReducer from "../LabelManegmant/LabelManegmant";
import BarcodeList from "../barcode/BarCodeData";
import DriverListsStatus from "../TRMManagement/DriverManagement/DriverListsStatus";
import DriverListsState from "../TRMManagement/DriverManagement/DriverListsState";
import consignmentOperationReducer from "../ConsignmentOperation/consignmentOperationReducer";
import OverViewData from "../OverviewInfo/OverviewInfo";
import CurrentHubReducer from "../CurrentHub/CurrentHubReducer";
import Authenticate from "../Authenticate/authenticate";
import ContradictionLists from "../Contradiction/Contradiction";
import ContradictionTrakingLists from "../Contradiction/ContradictionTraking";
import SettlementLists from "../Settlement/Settlement";
import ReturnConsignmentLists from "../ReturnConsignment/ReturnConsignment";
import PaymentDetailSlice from '../Settlement/payment-detail'
import ChangeDriverSlice from '../change-driver/index'
import PaymentActionSlice from '../Settlement/payment-action'
import ReceiptSlice from '../order-receipt/index'
import OTPSlice from '../otp/index'
import DeliveryExcelList from '../DeliveryData/DeliveryExcelData'
import CreditSlice from '../allocate-credit/index'
import {
  configureStore,
  combineReducers
 
} from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import PickupDistributionProgrammingLists from "../PickupDistributionProgramming/PickupDistributionProgramming";
import ExchangeProgrammingLists from "../PickupDistributionProgramming/ExchangeProgramming";
import PlaningLists from "../PickupDistributionProgramming/planning";
import PlaningConsignmentLists from "../PickupDistributionProgramming/ConsignmentPlaning";
import ActionModals from "redux/optime/optime";
import UnplanedSlice from "redux/unplaned-consignments/index"
import OrderDataSlice from "redux/order-management-new/index"
import DefinTerminlaSlice from "redux/define-terminal/index"
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
// @ts-ignore

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["authenticate", "userInfo", "currentHubInfo"],
};

const reducers = combineReducers({
  hub: HubList,
  userInfo: userInfoReducer,
  role: RolesList,
  paginate: PageNumber,
  authenticate: Authenticate,
  personnel: PersonnelList,
  serviceDefine: ServiceData,
  productDefine: ProductDefineList,
  customer: CustomerList,
  addressCorrection: AddressCorrectionList,
  pickup: PickupList,
  OrderManagement: OrderManagementList,
  Order: OrderList,
  delivery: DeliveryList,
  vehiclePlan: VehiclePlanList,
  thirdParty: ThirdPartyList,
  ADMVehicle: ADMVehicleList,
  serviceTime: ServiceTimeList,
  price: PriceList,
  serviceProvision: ServiceProvision,
  selectRowTable: selectRowTable,
  vendor: VendorList,
  vehicleModel: VehicleModelLists,
  route: RouteLists,
  HubType: HubTypeTable,
  Vehicle: VehicleLists,
  bags: BagsLists,
  gate: GateLists,
  dock: DockLists,
  exception: ExceptionLists,
  saleChannel: SalesChannelList,
  crmCustomer: CRMCustomerGroupReducer,
  customGeo: customGeoReducer,
  productG: ProductGroupsData,
  timeShift: timeShiftData,
  consignment: consignmentReducer,
  consignmentOperation: consignmentOperationReducer,
  driver: DriverListsData,
  BagPage: BagReducer,
  moveVehicle: VeheicleMove,
  lableManegment: lableReducer,
  barcode: BarcodeList,
  driverStatus: DriverListsStatus,
  driverState: DriverListsState,
  overView: OverViewData,
  currentHubInfo: CurrentHubReducer,
  contradiction: ContradictionLists,
  ContradictionTraking: ContradictionTrakingLists,
  settlement: SettlementLists,
  returnConsignment: ReturnConsignmentLists,
  pickupDistributionProgramming: PickupDistributionProgrammingLists,
  exchangeProgramming: ExchangeProgrammingLists,
  planing: PlaningLists,
  consignmentPlaning: PlaningConsignmentLists,
  optimeModal: ActionModals,
  UnplanedLists:UnplanedSlice,
  orderData:OrderDataSlice,
  paymentDetail:PaymentDetailSlice,
  changeDriver:ChangeDriverSlice,
  paymentAction:PaymentActionSlice,
  orderReceipt:ReceiptSlice,
  OTP:OTPSlice,
  deliveryExcel: DeliveryExcelList,
  allocateCredit:CreditSlice,
  defineTerminal:DefinTerminlaSlice
});

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
export type RootState = ReturnType<typeof reducers>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
export let persistor = persistStore(store);
