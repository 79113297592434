import { addEditDataAPI } from "services/CRUDServices";
import { OTPTypes } from "../types";

interface SendOTPTypes {
  setModals?: React.Dispatch<React.SetStateAction<OTPTypes>> ;
}

function useSendOTP({ setModals }: SendOTPTypes) {
   
  async function sendOTP(mobile) {
    try {
       await addEditDataAPI(`/customer-api/customer-management/customers/otp/${mobile}/customer-code-create`, "post",
         {}
    );
      setModals &&
        setModals((prev:OTPTypes) => ({
          ...prev,
          OTPModal: true,
          verificationModal: false,
        }));
    } catch {}
  }

  return { sendOTP };
}

export default useSendOTP;
