import DefineTerminal from "pages/BasicInformations/define-terminal";
import { lazy } from "react";

const Thirdparty = lazy(() => import("pages/Thirdparty/Thirdparty"));
const Transportation = lazy(
  () => import("pages/BasicInformations/Transportation")
);
const ADMVehicle = lazy(() => import("pages/ADMVehicle/ADMVehicle"));
const CustomerManagement = lazy(
  () => import("pages/CustomerManagement/CustomerManagement")
);
const ProductGroup = lazy(() => import("pages/ProductGroup/ProductGroup"));
const ServiceTime = lazy(() => import("pages/ServiceTime/ServiceTime"));
const CustomGeographic = lazy(
  () => import("pages/CustomGeographic/CustomGeographic")
);
export const childs: Array<ISideBarChild> = [
  {
    to: "/basic-information/thirdparty",
    component: <Thirdparty />,
    code: "1007",
    label: "اشخاص حقیقی/حقوقی",
    permissions: "view_thirdparty",
  },

  {
    to: "/basic-information/transportation",
    component: <Transportation />,
    code: "1008",
    label: "حمل و نقل",
    permissions: "view_transport",
  },
  {
    to: "/basic-information/ADMVehicle",
    component: <ADMVehicle />,
    code: "1009",
    label: "وسایل نقلیه اجاره‌ای",
    permissions: "view_ADM_vehicle",
  },
  {
    to: "/basic-information/customer-management",
    component: <CustomerManagement />,
    code: "1010",
    label: "مدیریت مشتریان",
    permissions: "view_customer_management",
  },
  {
    to: "/basic-information/product-group",
    component: <ProductGroup />,
    code: "",
    label: "گروه بندی محصولات",
    permissions: "view_product_group",
  },
  {
    to: "/basic-information/service-time",
    component: <ServiceTime />,
    code: "1012",
    label: "تعریف مدت ارائه خدمت",
    permissions: "view_time_commitment",
  },
  {
    to: "/basic-information/custom-geographic-category",
    component: <CustomGeographic />,
    code: "1013",
    label: "رده جغرافیایی سفارشی",
    permissions: "view_custom devision",
  },
  {
    to: "/basic-information/define-terminal",
    component: <DefineTerminal />,
    code: "",
    label: "تعریف ترمینال",
    permissions: "terminalDefenition",
  },
];
