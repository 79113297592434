import { FC } from "react";
import { componentItem } from "../../models/SwitchOptionsModel";

interface accessPageObject {}
interface SwitchOptionTableProps {
  accessPage: Array<accessPageObject>;
  selectedState?: React.Dispatch<any>;
}
const SwitchOptionTable: FC<SwitchOptionTableProps> = ({
  accessPage,
  selectedState,
}) => {
  return (
    <div className="flex justify-start items-center gap-5 mt-3 flex-wrap">
      {accessPage.map((item: any, index: number) => {
        //first if for include permision {
        //secoond if for include personalization componentItem[item.code].code === props.permission{

        return (
         <>
            {componentItem[item.code].JSXItem(
              item?.value,
              item?.permission,
              selectedState
            )}
          </>
        );
      })}
    </div>
  );
};

export default SwitchOptionTable;
