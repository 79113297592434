import React, { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useTable,
  usePagination,
  useRowSelect,
  useMountedLayoutEffect,
} from "react-table";
import Paginations from "../../global/Pagination/Pagination";
import { addRows, deleteRow } from "../../redux/selectRowTable/selectRowTable";
import Spinner from "../Spinner/Spinner";

const IndeterminateCheckbox = React.forwardRef(
  ({ data, indeterminate, ...rest }: any, ref) => {
    const { selectedRows } = useSelector((state: any) => state?.selectRowTable);
    const dispatch = useDispatch();

    const defaultRef = React.useRef<any>(null);
    const resolvedRef: any = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <input
          type="checkbox"
          ref={resolvedRef}
          onChange={(e) => {
            if (e.target.checked) {
              dispatch(addRows(data?.original));
            } else {
              dispatch(deleteRow(data?.original));
            }
          }}
          checked={
            selectedRows.filter((i: any) => i.id === data?.original?.id)
              .length > 0
          }
          className="accent-tomato"
        />
      </>
    );
  }
);

interface TableProps {
  columns: any;
  data: any;
  pageTable: any;
  selectable: any;
  loading: any;
  THWrapper: any;
  theSelectedRows: any;
  wrap?: boolean;
  emptyElement?:React.ReactNode
}
const Table: FC<TableProps> = ({
  columns,
  data,
  pageTable,
  selectable,
  loading,
  THWrapper,
  theSelectedRows,
  wrap,
  emptyElement
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    selectedFlatRows,
  }: // state: { pageIndex, pageSize },
  any = useTable(
    {
      columns,
      data,
    },
    usePagination,
    useRowSelect,
    (hooks) => {
      let isSelectable = selectable
        ? {
            Header: ({ getToggleAllPageRowsSelectedProps }: any) => (
              <div>
                <IndeterminateCheckbox
                  {...getToggleAllPageRowsSelectedProps()}
                />
              </div>
            ),
            // The cell can use the individual row's getToggleRowSelectedProps method
            // to the render a checkbox
            Cell: ({ row }: any) => (
              <div>
                <IndeterminateCheckbox
                  {...row.getToggleRowSelectedProps()}
                  data={row}
                />
              </div>
            ),
          }
        : {};
      hooks.visibleColumns.push((columns: any) => [
        // Let's make a column for selection
        {
          id: "selection",
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          ...isSelectable,
        },
        ...columns,
      ]);
    }
  );
  useMountedLayoutEffect(() => {
    theSelectedRows && theSelectedRows(selectedFlatRows);
  }, [theSelectedRows, selectedFlatRows]);


  
  return (
    <>
      <div className="overflow-auto  rounded-lg shadow-md  mt-6">
        <table
          cellPadding={"15px"}
          {...getTableProps()}
          className="border-collapse table-auto w-full bg-white table-striped rounded-lg text-center"
        >
          <thead className="bg-lightTomato h-12 rounded-lg text-dark ">
            {headerGroups.map((headerGroup: any) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: any) => (
                  <th
                    className={` font-normal ${THWrapper} `}
                    {...column.getHeaderProps()}
                  >
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row: any) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  className="even:bg-lightGray h-12 text-dark"
                >
                  {row.cells.map((cell: any) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        className={`${!wrap ? " max-w-[12rem]" : "whitespace-nowrap"}`}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>

        {loading ? (
          <Spinner />
        ) : (
          page.length === 0 && (
            emptyElement || <div className="h-20 centering w-full"> موردی یافت نشد </div>
          )
        )}
      </div>
      <Paginations pageData={pageTable} />
    </>
  );
};
interface StaticTableProps {
  data?: any;
  column?: any;
  pagination?: any;
  selectable?: any;
  loading?: any;
  THWrapper?: any;
  theSelectedRows?: any;
  wrap?:boolean

  emptyElement?:any
}
const StaticTable: FC<StaticTableProps> = ({
  data,
  column,
  pagination,
  selectable,
  loading = false,
  THWrapper = "min-w-fit",
  theSelectedRows,
  wrap,
  emptyElement
}) => {

  return (
    <Table
      columns={column}
      data={data}
      pageTable={pagination}
      selectable={selectable}
      loading={loading}
      THWrapper={THWrapper}
      theSelectedRows={theSelectedRows}
      wrap={wrap}
      emptyElement={emptyElement}

    />
  );
};

export default StaticTable;
